<template>
    <section id="politica-privacidad" class="py-5 bg-light">
      <div class="container">
        <button class="btn btn-custom mb-5" @click="$router.back()">
        ← Volver
        </button>
        <h1 class="mb-4">Política de Privacidad de PortalUbi</h1>
  
        <p>
          En PortalUbi valoramos la protección de tus datos personales como una oportunidad para generar valor y 
          ofrecerte un mejor servicio. Con el fin de brindarte productos y/o servicios relacionados con bienes raíces, 
          vamos a tratar tus datos personales.
        </p>
        <p>
          Tu privacidad es muy importante para nosotros y tomamos todas las medidas necesarias para protegerla. 
          Con este objetivo, te informamos en esta Política de Privacidad (“Política”) cómo PortalUbi procesa 
          tus datos personales y cuáles son tus derechos para controlarlos.
        </p>
        <p>
          Al aceptar esta Política, autorizas expresamente que tratemos tus datos personales de la forma aquí 
          descrita y de conformidad con las leyes aplicables.
        </p>
  
        <h2 class="mt-5">¿Quién es el responsable del tratamiento de los datos personales?</h2>
        <p>
          Los datos personales que nos proporciones serán registrados en una base de datos de la que 
          <strong>PortalUbi</strong> es responsable. Dependiendo del país en el que residas, una o más entidades 
          de PortalUbi pueden ser responsables del tratamiento de tus datos. Consulta los detalles de estas 
          entidades en el apartado correspondiente de esta Política.
        </p>
  
        <h2 class="mt-5">¿Qué datos personales recolectamos y tratamos?</h2>
        <h5>Datos que nos proporcionas directamente al usar nuestros servicios:</h5>
        <ul>
          <li>Nombre y apellido</li>
          <li>Número de teléfono</li>
          <li>Correo electrónico</li>
          <li>Número de documento de identificación</li>
          <li>Otros datos relevantes que decidas proporcionarnos</li>
        </ul>
  
        <h5 class="mt-4">Datos que recolectamos de manera automática:</h5>
        <ul>
          <li>Información recopilada mediante el uso de cookies, como tu dirección IP, páginas visitadas, 
          anuncios en los que has hecho clic, etc.</li>
          <li>Datos de navegación y transaccionales generados durante tu interacción con PortalUbi.</li>
        </ul>
  
        <h5 class="mt-4">Datos que podemos recopilar de otras fuentes:</h5>
        <ul>
          <li>Información adicional como fecha de nacimiento, nacionalidad, u otros datos financieros o crediticios.</li>
        </ul>
  
        <p class="mt-3">
          No estás obligado a proporcionarnos tus datos personales; sin embargo, la negativa o inexactitud en la 
          información podría impedirte utilizar ciertos servicios de PortalUbi.
        </p>
  
        <h2 class="mt-5">¿Qué hacemos con tus datos personales?</h2>
        <p>
          Procesamos tus datos personales con las siguientes finalidades:
        </p>
        <ul>
          <li>Compartir tus datos con anunciantes para que te contacten sobre propiedades o servicios de tu interés.</li>
          <li>Agendar visitas o reservas de inmuebles.</li>
          <li>Contactarte vía correo electrónico, SMS, WhatsApp u otros canales para informarte sobre bienes y servicios.</li>
          <li>Verificar antecedentes y evaluar tu capacidad económica cuando realices reservas o compras.</li>
          <li>Enviar recordatorios para completar procesos iniciados.</li>
          <li>Realizar estudios de mercado para mejorar nuestros productos y servicios.</li>
          <li>Compartir tus datos con aliados comerciales para ofrecerte servicios relacionados.</li>
        </ul>
        <p>
          Si deseamos utilizar tus datos para finalidades adicionales, te informaremos y, en su caso, solicitaremos tu consentimiento.
        </p>
  
        <h2 class="mt-5">¿Cómo compartimos tus datos personales?</h2>
        <p>
          Tus datos personales pueden ser compartidos con:
        </p>
        <ul>
          <li>Anunciantes y agentes que publican propiedades o servicios relacionados.</li>
          <li>Aliados comerciales con acuerdos para ofrecerte bienes y servicios.</li>
          <li>Entidades para verificación crediticia y evaluación económica.</li>
          <li>Afiliadas de PortalUbi.</li>
          <li>Proveedores de servicios que procesen datos por cuenta de PortalUbi.</li>
          <li>Autoridades competentes.</li>
          <li>En casos de fusiones, adquisiciones u operaciones similares.</li>
        </ul>
        <p>
          Siempre que compartamos tus datos con terceros, estableceremos acuerdos que garanticen la protección 
          de tus datos conforme a la legislación aplicable.
        </p>
  
        <h2 class="mt-5">¿Cómo se realizan transferencias internacionales de datos personales?</h2>
        <p>
          Nuestra infraestructura tecnológica y nuestros socios pueden estar ubicados en diferentes países. 
          Es posible que los destinatarios de tus datos se encuentren fuera de tu país de residencia. 
          En tal caso, adoptaremos las medidas necesarias para transferir tus datos de forma segura y conforme 
          a la normativa vigente.
        </p>
  
        <h2 class="mt-5">¿Por cuánto tiempo almacenamos tus datos personales?</h2>
        <p>
          Almacenaremos tus datos personales durante el tiempo necesario para:
        </p>
        <ul>
          <li>Cumplir con las finalidades para las que fueron recolectados.</li>
          <li>Proteger nuestros derechos y los de terceros.</li>
          <li>Cumplir con obligaciones legales y contractuales.</li>
        </ul>
  
        <h2 class="mt-5">¿Cómo cuidamos y aseguramos tus datos personales?</h2>
        <p>
          PortalUbi cumple con la normativa aplicable y adopta medidas técnicas, organizativas y administrativas 
          para proteger tus datos personales, siguiendo estándares de la industria para evitar accesos 
          no autorizados, pérdida o divulgación indebida.
        </p>
  
        <h2 class="mt-5">¿Cómo puedes ejercer tus derechos?</h2>
        <p>
          Tienes derechos sobre tus datos personales, tales como:
        </p>
        <ul>
          <li>Acceso y confirmación del tratamiento.</li>
          <li>Actualización, rectificación o cancelación de tus datos.</li>
          <li>Oposición al envío de comunicaciones comerciales.</li>
          <li>Portabilidad de tus datos y otros derechos estipulados por ley.</li>
        </ul>
        <p>
          Para ejercer tus derechos o realizar consultas, envía un correo a: 
          <strong>privacidad@portalubi.com</strong>.
        </p>
        <p>
          También tienes derecho a presentar una queja ante la autoridad de protección de datos competente en tu país.
        </p>
  
        <h2 class="mt-5">Cookies y otras tecnologías</h2>
        <p>
          Reconoces y aceptas que en nuestro sitio se utilizan cookies, web beacons y otras tecnologías 
          similares para:
        </p>
        <ul>
          <li>Conocer tus intereses y comportamiento en nuestro sitio.</li>
          <li>Mejorar tu experiencia de usuario.</li>
          <li>Analizar páginas visitadas y patrones de navegación.</li>
          <li>Personalizar contenido y anuncios, y mejorar nuestros servicios.</li>
        </ul>
        <p>
          Estas tecnologías también nos ayudan a que no tengas que iniciar sesión repetidamente en 
          una misma sesión de navegación. Terceros pueden instalar cookies en ciertas páginas de 
          nuestro sitio, pero esta Política no regula cookies de terceros.
        </p>
        <p>
          Puedes gestionar o eliminar cookies a través de la configuración de tu navegador. Ten en 
          cuenta que desactivar cookies puede limitar el acceso a algunas funcionalidades del sitio.
        </p>
  
        <h2 class="mt-5">Cambios en la Política de Privacidad</h2>
        <p>
          Si realizamos cambios significativos en esta Política, te informaremos a través de notificaciones 
          en nuestros canales habituales (por ejemplo, correo electrónico), en nuestro sitio web o 
          mediante anuncios en la plataforma. En los casos que requieran tu consentimiento, te comunicaremos 
          los cambios para que decidas si estás de acuerdo.
        </p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  

  