<!-- src/components/PropertiesMap.vue -->
<template>
    <div class="properties-map-container container my-5">
      <h2 class="mb-4 text-center">Propiedades en el Mapa</h2>
      
     <!-- Campo de búsqueda por Región o Comuna con autocompletado -->
<div class="mb-3 position-relative">
  <input
    type="text"
    class="form-control"
    placeholder="Buscar por Región o Comuna"
    v-model="selectedLocation"
    @input="onLocationInput"
    @focus="onLocationFocus"
    @blur="onLocationBlur"
    autocomplete="off"
  />
  <!-- Lista de sugerencias -->
  <ul v-if="showLocationSuggestions" class="list-group position-absolute w-100 autocomplete-dropdown">
    <li
      class="list-group-item list-group-item-action"
      v-for="location in filteredLocations"
      :key="location.key"
      @mousedown.prevent="selectLocation(location)"
    >
      {{ location.descripcion }}
      <span class="badge bg-secondary float-end">{{ location.type }}</span>
    </li>
  </ul>
</div>


      <!-- Sección de miniaturas y mapa -->
      <div class="row">
        <!-- Panel lateral con miniaturas -->
        <div class="col-md-2" style="max-height: 80vh; overflow-y: auto;">
          <!-- Dentro del template de PropertiesMap.vue, en la columna de miniaturas -->
<div
  v-for="propiedad in properties"
  :key="propiedad.id"
  class="property-thumbnail mb-3 card tarjeta-propiedad shadow-sm"
  style="cursor: pointer;"
  @click="navigateToDetail(propiedad.id)"
  @mouseover="highlightMarker(propiedad)"
  @mouseleave="resetMarker(propiedad)"
>
  <img :src="getMainImage(propiedad.images)" alt="Imagen de la propiedad" class="img-fluid">
    <div class="card-body pt-0 pb-2">
        <!-- Título de la Propiedad con Tipo de Transacción y Tipo de Propiedad en Español -->
        <h5 class="card-title mb-0">
            <router-link
            :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }"
            class="text-decoration-none text-dark fw-200" 
            >
            <span v-if="['Casa', 'Departamento', 'Parcela'].includes(propiedad.property_type)">
               <strong> {{ propiedad.property_type }} en {{ propiedad.transaction_type }} </strong>
            </span>
            </router-link>
        </h5>
        <h5 class="card-price">
            <span class="small fw-200">
                {{ getCurrencySymbol(propiedad.currency) }} {{ formatPrice(propiedad.price) }}
            </span>
        </h5>

        <!-- Información de la Propiedad con Detalles en Fila -->
        <div class="info-propiedad d-flex flex-wrap fw-200">
            <!-- Dormitorios -->
            <div
            v-if="propiedad.rooms && propiedad.rooms !== 'Sin Datos'"
            class="d-flex align-items-center"
            >
            <i class="bi bi-house-door-fill me-1" aria-hidden="true"></i>
            <span class="small">{{ propiedad.rooms }} Dormitorio(s)</span>
            </div>

            <!-- Baños -->
            <div
            v-if="propiedad.bathrooms && propiedad.bathrooms !== 'Sin Datos'"
            class="d-flex align-items-center"
            >
            <span class="small">&nbsp;<strong>|</strong> {{ propiedad.bathrooms }} Baño(s)</span>
            </div>

            <!-- Estacionamientos -->
            <div
            v-if="['Casa', 'Departamento'].includes(propiedad.property_type)"
            class="d-flex align-items-center"
            >
            <i class="bi bi-car-front-fill me-1" aria-hidden="true"></i>
            <span class="small">
                <template v-if="propiedad.parking > 0">
                {{ propiedad.parking }} Estacionamiento(s)
                </template>
                <template v-else>
                Sin estacionamiento
                </template>
            </span>
            </div>

            <!-- Área Total -->
            <div
            v-if="propiedad.floor_area && propiedad.floor_area !== 'Sin Datos'"
            class="d-flex align-items-center"
            >
            <span class="small">Tamaño&nbsp;<strong>|</strong> {{ propiedad.floor_area }} m²</span>
            </div>

            <!-- Área de Parcela (solo para parcelas) -->
            <div
            v-if="
                propiedad.property_type === 'Parcela' &&
                propiedad.plot_area &&
                propiedad.plot_area !== 'Sin Datos'
            "
            class="d-flex align-items-center"
            >
            <i class="bi bi-geo-alt-fill me-1" aria-hidden="true"></i>
            <span class="small">Área de Parcela: {{ propiedad.plot_area }} m²</span>
            </div>
            
            <!-- Comuna -->
            <div
            v-if="propiedad.city && propiedad.city !== 'Sin Datos'"
            class="d-flex align-items-center comuna mt-1"
            style="width: 100%;">
            <span class="small"><strong>{{ propiedad.city }}</strong></span>
            </div>
        </div>

        <div class="agente-propiedad">
            <span class="text-muted fw-200">Corredor: {{ propiedad.agent_name }}</span>
        </div>
        </div>
    </div>

        </div>
  
        <!-- Mapa -->
        <div class="col-md-10" style="height: 80vh;">
          <div ref="map" style="width: 100%; height: 100%;"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { getProperties } from '@/services/api.js'; 
  /* global google */
  const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
  
  export default {
    name: 'PropertiesMap',
    data() {
      return {
        selectedLocation: '',            // Valor del input de autocompletado
        selectedLocationType: '',        // Tipo seleccionado (Región o Comuna)
        selectedLocationValue: '',       // Valor asociado de la región/comuna
        regiones: [],
        comunas: [],
        locations: [],
        filteredLocations: [],
        showLocationSuggestions: false,
        properties: [],
        map: null,
        markers: [],
        infoWindow: null,
        loading: true,
        error: null,
        // Filtros que se leerán de la URL
        filters: {
          transactionType: '',
          propertyType: '',
        },
        selectedComuna: null,
        closeTimeoutId: null, // Propiedad para almacenar el ID del temporizador
      };
    },
    methods: {
      onLocationInput() {
    if (this.selectedLocation.length > 0) {
      const searchTerm = this.selectedLocation.toLowerCase();
      this.filteredLocations = this.locations.filter((location) =>
        location.descripcion.toLowerCase().includes(searchTerm)
      );
      this.showLocationSuggestions = this.filteredLocations.length > 0;
    } else {
      this.filteredLocations = [];
      this.showLocationSuggestions = false;
      this.selectedLocationType = '';
      this.selectedLocationValue = '';
    }
  },
  selectLocation(location) {
    this.selectedLocation = location.descripcion;
    this.selectedLocationType = location.type;
    this.selectedLocationValue = location.descripcion; // Puedes ajustar según formato necesario
    this.filteredLocations = [];
    this.showLocationSuggestions = false;
    // Actualizar el filtro de propiedades con la nueva comuna/región seleccionada
    this.selectedComuna = this.selectedLocation; 
    this.fetchPropertiesForMap();
  },
  onLocationBlur() {
    // Retrasar el ocultamiento para permitir la selección con el mouse
    setTimeout(() => {
      this.showLocationSuggestions = false;
    }, 100);
  },
  onLocationFocus() {
    if (this.filteredLocations.length > 0) {
      this.showLocationSuggestions = true;
    }
  },

  // Función para obtener comunas desde la API
  fetchComunas() {
    const comunasApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/comunas";
    return axios.get(comunasApiUrl)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.comunas = response.data
            .filter((comuna) => comuna.descripcion)
            .map((comuna) => ({
              key: `comuna-${comuna.valor}`,
              descripcion: comuna.descripcion,
              valor: comuna.valor,
              type: 'Comuna',
            }));
        } else {
          this.comunas = [];
        }
      })
      .catch((err) => {
        console.error('Error al obtener comunas:', err);
        this.comunas = [];
      });
  },

  // Función para obtener regiones desde la API
  fetchRegiones() {
    const regionesApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/regiones";
    return axios.get(regionesApiUrl)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.regiones = response.data
            .filter((region) => region.descripcion)
            .map((region) => ({
              key: `region-${region.valor}`,
              descripcion: region.descripcion,
              valor: region.valor,
              type: 'Región',
            }));
        } else {
          this.regiones = [];
        }
      })
      .catch((err) => {
        console.error('Error al obtener regiones:', err);
        this.regiones = [];
      });
  },

  combineLocations() {
    this.locations = [...this.regiones, ...this.comunas].sort((a, b) =>
      a.descripcion.localeCompare(b.descripcion)
    );
  },

  async loadLocationData() {
    await Promise.all([this.fetchRegiones(), this.fetchComunas()]);
    this.combineLocations();
  },
      onSearchComuna() {
    // Actualizamos la búsqueda de propiedades con la comuna seleccionada
    this.fetchPropertiesForMap();
    // Opcional: actualizar la URL con el query param de la comuna, si se desea persistencia en la URL
    this.$router.replace({ query: { 
      ...this.$route.query, 
      city: this.selectedComuna 
    }});
  },

      highlightMarker(propiedad) {
    if (propiedad.marker) {
      // Por ejemplo, iniciar una animación de rebote para resaltar el marcador
      propiedad.marker.setAnimation(google.maps.Animation.BOUNCE);
      // Alternativamente, puedes cambiar el icono del marcador para resaltar
      // propiedad.marker.setIcon('url_del_icono_destacado.png');
    }
  },
  
  resetMarker(propiedad) {
    if (propiedad.marker) {
      // Detener la animación o restaurar el icono original
      propiedad.marker.setAnimation(null);
      // propiedad.marker.setIcon('url_del_icono_original.png');
    }
  },
      async loadGoogleMapsScript() {
        return new Promise((resolve, reject) => {
          if (window.google && window.google.maps) {
            resolve();
            return;
          }
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;
          script.async = true;
          script.defer = true;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      },
      async fetchPropertiesForMap() {
        this.loading = true;
        this.error = null;
        const params = {
          page: 1,
          page_size: 100,
        };
        if (this.selectedComuna) {
          params.city = this.selectedComuna;
        }
        if (this.filters.transactionType) {
          params.transaction_type = this.filters.transactionType;
        }
        if (this.filters.propertyType) {
          params.property_type = this.filters.propertyType;
        }
        if (this.selectedComuna) {
          params.city = this.selectedComuna;
        }
        try {
          const response = await getProperties(params);
          if (response && response.properties) {
            this.properties = response.properties.map((prop) => this.processProperty(prop));
          } else {
            this.error = 'No se encontraron propiedades para los filtros aplicados.';
            this.properties = [];
          }
        } catch (err) {
          console.error('Error al obtener propiedades:', err);
          this.error = 'Hubo un problema al obtener las propiedades.';
          this.properties = [];
        } finally {
          this.loading = false;
        }
      },
      processProperty(propiedad) {
    const getAttributeValue = (name) => {
      const attr = propiedad.attributes.find(a => a.name === name);
      return attr ? attr.value : 'Sin Datos';
    };

    const originalTransactionType = getAttributeValue('TransactionType') || 'Sin Tipo';
    const transactionTypeMap = { 'For Sale': 'Venta', 'For Rent': 'Arriendo' };
    const translatedTransactionType = transactionTypeMap[originalTransactionType] || originalTransactionType;

    const originalPropertyType = getAttributeValue('PropertyType') || 'Sin Tipo';
    const propertyTypeMapSpanish = { House: 'Casa', Apartment: 'Departamento', Land: 'Parcela' };
    const translatedPropertyType = propertyTypeMapSpanish[originalPropertyType] || originalPropertyType;

    return {
      id: propiedad.id,
      property_type: translatedPropertyType,
      transaction_type: translatedTransactionType,
      price: propiedad.price || 0,
      currency: propiedad.currency || 'CLP',
      rooms: getAttributeValue('Rooms'),
      bathrooms: getAttributeValue('Bathrooms'),
      floor_area: getAttributeValue('FloorArea'),
      plot_area: getAttributeValue('PlotArea'),
      parking: getAttributeValue('Parking'),
      city: propiedad.location ? propiedad.location.city : 'Sin Datos',
      agent_name: propiedad.agency ? propiedad.agency.contactname : 'Sin Datos',
      location: propiedad.location,
      images: propiedad.pictures ? propiedad.pictures.map(pic => pic.source) : [],
    };
  },
      getMainImage(images) {
        return images && images.length ? images[0] : require('@/assets/regions/default-property.jpg');
      },
      formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
      getCurrencySymbol(currency) {
        const symbols = { CLP: '$', USD: '$', EUR: '€' };
        return symbols[currency] || currency;
      },
      navigateToDetail(id) {
        this.$router.push({ name: 'PropertyDetail', params: { id } });
      },
      applyFiltersFromRoute() {
        const query = this.$route.query;
        if (query.transaction_type) {
          this.filters.transactionType = query.transaction_type;
        }
        if (query.property_type) {
          this.filters.propertyType = query.property_type;
        }
        if (query.city) {
          this.selectedComuna = query.city;
        }
      },
      initMap() {
        // Centro predeterminado en Santiago
        const center = { lat: -33.4489, lng: -70.6693 };
        this.map = new google.maps.Map(this.$refs.map, {
          center,
          zoom: 12,
        });
        this.infoWindow = new google.maps.InfoWindow();
        this.addMarkers();
      },
      addMarkers() {
  // Limpiar marcadores existentes
  this.markers.forEach(marker => marker.setMap(null));
  this.markers = [];
  
  // Reiniciar el ID del temporizador
  this.closeTimeoutId = null;
  
  this.properties.forEach(propiedad => {
    const { latitude, longitude } = propiedad.location;
    if (!latitude || !longitude) return;
    const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
    
    const marker = new google.maps.Marker({
      position,
      map: this.map,
      title: `${propiedad.property_type} en ${propiedad.transaction_type}`,
    });

    // (Configurar listeners para marker.mouseover, mouseout, click como antes)
    marker.addListener('mouseover', () => { /* ... */ });
    marker.addListener('mouseout', () => { /* ... */ });
    marker.addListener('click', () => {
      this.navigateToDetail(propiedad.id);
    });

    // Almacenar el marcador en la propiedad y en el array de marcadores
    propiedad.marker = marker;
    this.markers.push(marker);

    marker.addListener('mouseover', () => {
      // Si existe un temporizador pendiente para cerrar, cancelarlo
      if (this.closeTimeoutId) {
        clearTimeout(this.closeTimeoutId);
        this.closeTimeoutId = null;
      }

      const content = `
        <div style="width:250px; font-family: Arial, sans-serif;">
          <div class="imagen-propiedad position-relative" style="width:100%; height:150px; overflow:hidden;">
            <img src="${this.getMainImage(propiedad.images)}" 
                 alt="Imagen de la propiedad" 
                 style="width:100%; height:100%; object-fit:cover;" 
                 class="img-fluid"/>
          </div>
          <div class="card card-body pt-2">
            <span class="card-title fw-300 mb-0" style="margin-bottom:0.5rem;">
              ${['Casa', 'Departamento', 'Parcela'].includes(propiedad.property_type) ? 
                `${propiedad.property_type} en ${propiedad.transaction_type}` : ''
              }
            </span>
            <h5 class="card-price fw-300 mb-1" style="margin:0 0 0.5rem 0;">
              <span class="small">
                <strong>${this.getCurrencySymbol(propiedad.currency)} ${this.formatPrice(propiedad.price)}</strong>
              </span>
            </h5>
            <div class="info-propiedad fw-200 mb-0" style="display:flex; flex-wrap:wrap; font-size:0.8rem; margin-bottom:0.5rem;">
              ${propiedad.rooms && propiedad.rooms !== 'Sin Datos' ? 
                `<div style="margin-right:0.5rem; display:flex; align-items:center;">
                   <i class="bi bi-house-door-fill" aria-hidden="true" style="margin-right:0.25rem;"></i>
                   ${propiedad.rooms} Dormitorio(s)
                 </div>` : ''
              }
              ${propiedad.bathrooms && propiedad.bathrooms !== 'Sin Datos' ? 
                `<div style="margin-right:0.5rem; display:flex; align-items:center;">
                   | ${propiedad.bathrooms} Baño(s)
                 </div>` : ''
              }
              ${['Casa', 'Departamento'].includes(propiedad.property_type) ? 
                `<div style="margin-right:0.5rem; display:flex; align-items:center;">
                   <i class="bi bi-car-front-fill" aria-hidden="true" style="margin-right:0.25rem;"></i>
                   ${propiedad.parking && propiedad.parking > 0 ? `${propiedad.parking} Estacionamiento(s)` : 'Sin estacionamiento'}
                 </div>` : ''
              }
              ${propiedad.floor_area && propiedad.floor_area !== 'Sin Datos' ? 
                `<div style="margin-right:0.5rem; display:flex; align-items:center;">
                   | ${propiedad.floor_area} m²
                 </div>` : ''
              }
              ${propiedad.property_type === 'Parcela' && propiedad.plot_area && propiedad.plot_area !== 'Sin Datos' ? 
                `<div style="margin-right:0.5rem; display:flex; align-items:center;">
                   <i class="bi bi-geo-alt-fill" aria-hidden="true" style="margin-right:0.25rem;"></i>
                   Área de Parcela: ${propiedad.plot_area} m²
                 </div>` : ''
              }
              ${propiedad.city && propiedad.city !== 'Sin Datos' ? 
                `<div style="display:flex; align-items:center;" class="mt-1">
                   <strong>${propiedad.city}</strong>
                 </div>` : ''
              }
            </div>
          </div>
        </div>
      `;

      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, marker);
    });

    marker.addListener('mouseout', () => {
      // Iniciar temporizador para cerrar la ventana después de 2 segundos
      this.closeTimeoutId = setTimeout(() => {
        this.infoWindow.close();
      }, 1000);
    });

    marker.addListener('click', () => {
      this.navigateToDetail(propiedad.id);
    });

    this.markers.push(marker);
  });
}

    },
    async mounted() {
      await this.loadGoogleMapsScript();
      await this.loadLocationData();       // Carga regiones y comunas
      this.applyFiltersFromRoute();
      await this.fetchPropertiesForMap();
      this.initMap();
    },
    watch: {
      // Si cambian las propiedades, actualizar los marcadores
      properties() {
        if (this.map) {
          this.addMarkers();
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .properties-map-container {
    /* Estilos personalizados */
  }
  .property-thumbnail img {
    max-height: 100px;
    object-fit: cover;
  }
  .imagen-propiedad {
  position: relative;
  height: 80px ;
}

.card-img,
.card-img-top {
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.gm-style-iw-chr {
    display: none !important;
}
  </style>
  