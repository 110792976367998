<!-- HomePage.vue -->
<template>
  <div id="app">
    <!-- Carrusel en el Encabezado -->
    <div class="carousel-container mb-5 mt-5">
      <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel">
        <!-- Indicadores del Carrusel -->
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3">
          </button>
        </div>

        <!-- Elementos del Carrusel -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../assets/slide/1.jpg"
              class="d-block w-100"
              alt="Slide 1">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/2.jpg"
              class="d-block w-100"
              alt="Slide 2">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/3.jpg"
              class="d-block w-100"
              alt="Slide 3">
          </div>
        </div>

        <!-- Controles del Carrusel -->
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
    </div>
    <!-- Fin del Carrusel -->

    <div class="container mt-5">
      <!-- Formulario de Filtros -->
      <form @submit.prevent="applyFilters" class="mb-4 fw-200 p-4">
      <div class="row g-3">
        <!-- Filtro de Ubicación (Región y Comuna) -->
        <div class="col-md-3 position-relative">
          <label for="location" class="form-label">Región o Comuna</label>
          <input
            type="text"
            v-model="selectedLocation"
            id="location"
            class="form-control fw-200 p-2"
            @input="onLocationInput"
            @blur="onLocationBlur"
            @focus="onLocationFocus"
            autocomplete="off"
            placeholder="Ingresa Región o Comuna"
          />
          <!-- Lista de sugerencias -->
          <ul v-if="showLocationSuggestions" class="list-group position-absolute w-100 autocomplete-dropdown">
            <li
              class="list-group-item list-group-item-action"
              v-for="location in filteredLocations"
              :key="location.key"
              @mousedown.prevent="selectLocation(location)"
            >
              {{ location.descripcion }}
              <span class="badge bg-secondary float-end">{{ location.type }}</span>
            </li>
          </ul>
        </div>

        <!-- Filtro Operación -->
        <div class="col-md-3">
          <label for="operacion" class="form-label">Operación</label>
          <select v-model="selectedOperacion" id="operacion" class="form-select fw-200 p-2">
            <option value="">Seleccionar operación</option>
            <option
              v-for="operacion in operaciones"
              :key="operacion.valor"
              :value="operacion.descripcion"
            >
              {{ operacion.descripcion }}
            </option>
          </select>
        </div>

        <!-- Nuevo Filtro: Tipo de Propiedad -->
        <div class="col-md-3">
          <label for="tipoPropiedad" class="form-label">Tipo de Propiedad</label>
          <select v-model="selectedTipoPropiedad" id="tipoPropiedad" class="form-select fw-200 p-2">
            <option value="">Cualquiera</option>
            <option value="House">Casa</option>
            <option value="Apartment">Departamento</option>
            <option value="Office">Oficina</option>
            <option value="Land">Parcela</option>
            <!-- Agrega más opciones según tus necesidades -->
          </select>
        </div>

        <div class="col-md-3 align-self-end ">
          <button type="submit" class="btn bg-dark fw-300 p-2">Buscar</button>
          <button type="button" class="btn btn-outline-secondary ms-2" @click="goToMap">
  Ver en Mapa
</button>
        </div>
        <!-- Otros filtros (opcional) -->
      </div>

      
    </form>

      <!-- Mensaje de error -->
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <!-- Estado de carga -->
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>

      <!-- Resultados de la búsqueda -->
      <div v-if="!loading && !error">
        <!-- Título de la Sección -->
        <h3 class="mb-3">Últimas Propiedades</h3>
        <router-link to="/all-properties" class="btn btn-outline-primary btn-sm">
            Ver más
          </router-link>

        <!-- Lista de Propiedades con 4 por fila -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 propiedades-listado">
          <div class="col mb-3" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class="card tarjeta-propiedad h-100 shadow-sm">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                <div class="imagen-propiedad position-relative">
                  <img
                    :src="getMainImage(propiedad.images)"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title"
                    @error="handleImageError($event)">
                </div>
              </router-link>
              <div class="card-body">
                <!-- Título de la Propiedad en Español -->
                <h5 class="card-title">
                  <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                    {{ propiedad.property_type }} en {{ propiedad.transaction_type }}
                  </router-link>
                </h5>
                <h5 class="card-price">
                  <span class="small">
                    {{ getCurrencySymbol(propiedad.currency) }} {{ formatPrice(propiedad.price) }}
                  </span>
                </h5>

                <!-- Información de la Propiedad con Detalles en Fila -->
                <div class="info-propiedad d-flex flex-wrap">
                  <!-- Dormitorios -->
                  <div v-if="propiedad.rooms && propiedad.rooms !== 'Sin Datos'" class="d-flex align-items-center">
                    <i class="bi bi-house-door-fill me-1" aria-hidden="true"></i>
                    <span class="small">{{ propiedad.rooms }} Dormitorio(s)</span>
                  </div>
                  
                  <!-- Baños -->
                  <div v-if="propiedad.bathrooms && propiedad.bathrooms !== 'Sin Datos'" class="d-flex align-items-center">
                    <span class="small">&nbsp;<strong>| </strong> {{ propiedad.bathrooms }} Baño(s)</span>
                  </div>
                  
                  <!-- Estacionamientos -->
                  <div v-if="['Casa', 'Departamento', 'Oficina'].includes(propiedad.property_type)" class="d-flex align-items-center">
                    <i class="bi bi-car-front-fill me-1" aria-hidden="true"></i>
                    <span class="small">
                      <template v-if="propiedad.parking > 0">
                        {{ propiedad.parking }} Estacionamiento(s)
                      </template>
                      <template v-else>
                        Sin estacionamiento
                      </template>
                    </span>
                  </div>
                  
                  <!-- Área Total -->
                  <div v-if="propiedad.floor_area && propiedad.floor_area !== 'Sin Datos'" class="d-flex align-items-center">
                    <span class="small">&nbsp;<strong>| </strong>{{ propiedad.floor_area }} m²</span>
                  </div>
                  
                  <!-- Área de Parcela (solo para parcelas) -->
                  <div v-if="propiedad.property_type === 'Parcela' && propiedad.plot_area && propiedad.plot_area !== 'Sin Datos'" class="d-flex align-items-center">
                    <i class="bi bi-geo-alt-fill me-1" aria-hidden="true"></i>
                    <span class="small">Área de Parcela: {{ propiedad.plot_area }} m²</span>
                  </div>
                  
                  <!-- Comuna -->
                  <div v-if="propiedad.city && propiedad.city !== 'Sin Datos'" class="d-flex align-items-center comuna">
                    <span class="small">{{ propiedad.city }}</span>
                  </div>
                </div>
                
                <div class="agente-propiedad mt-2">
                  <span class="text-muted">Corredor: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Controles de Paginación (Eliminados) -->
        <!-- Ya no se mostrarán controles de paginación para limitar a 16 propiedades -->

      </div>

      <!-- Sección Nueva: Tarjetas Dinámicas de Regiones -->
      <div class="mt-5">
        <br>
        <h3 class="mb-4 mt-5 text-center">Explora las propiedades por regiones de Chile</h3>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-6 g-6">
          <div class="col cont.reg" v-for="region in allRegions" :key="region.valor">
            <div class="region-card text-center h-100">
              <div class="card-body d-flex flex-column align-items-center justify-content-center">
               
                <router-link :to="`/region/${getTrimmedRegionName(region.descripcion)}`" class="mt-auto">
                  <img
                    :src="getRegionImage(region.descripcion)"
                    :alt="'Región de ' + getTrimmedRegionName(region.descripcion)"
                    class="region-image"
                    @error="handleImageError($event)">
                  <h5 class="card-title">{{ getTrimmedRegionName(region.descripcion) }}</h5>
                </router-link>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin de la Sección Nueva -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      properties: [],
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 16, // Cambiado a 16
      perPageOptions: [16], // Opcional: Solo 16 para limitar
      total: 16, // Limitar total a 16

      // Opciones de filtros
      tiposPropiedad: [],
      regiones: [],
      comunas: [],
      operaciones: [],

      // Filtros seleccionados
      selectedTipoPropiedad: '',
      selectedOperacion: '',
      selectedLocation: '',
      selectedLocationType: '',
      selectedLocationValue: '',

      // Datos para autocompletado de ubicación
      locations: [],
      filteredLocations: [],
      showLocationSuggestions: false,

      // Mapeo de Tipos de Propiedad
      propertyTypeMapSpanish: {
        'House': 'Casa',
        'Apartment': 'Departamento',
        'Land': 'Parcela',
        'Office': 'Oficina',
        // Añade más mapeos si es necesario
      },

      // Mapeo de Tipos de Transacción
      transactionTypeMap: {
        'For Sale': 'Venta',
        'For Rent': 'Arriendo'
      },

      // Datos de todas las regiones para las tarjetas dinámicas
      allRegions: [],
    };
  },
  computed: {
    isAuthenticated() {
      return !!this.$clerk.user;
    },
    // Número total de páginas
    totalPages() {
      return 1; // Solo una página al limitar a 16
    },
    // Propiedades a mostrar en la página actual
    paginatedProperties() {
      return this.properties;
    },
    // Eliminado: Rango de páginas para mostrar en la paginación
  },
  methods: {
    goToMap() {
    const query = {};

    if (this.selectedTipoPropiedad) {
      query.property_type = this.selectedTipoPropiedad;
    }
    if (this.selectedOperacion) {
      const operacionLower = this.selectedOperacion.toLowerCase();
      if (operacionLower.includes('arriendo')) {
        query.transaction_type = 'For Rent';
      } else if (operacionLower.includes('venta')) {
        query.transaction_type = 'For Sale';
      } else {
        query.transaction_type = this.selectedOperacion;
      }
    }
    if (this.selectedLocation && this.selectedLocationType) {
      if (this.selectedLocationType === 'Región') {
        query.state = this.selectedLocationValue;
      } else if (this.selectedLocationType === 'Comuna') {
        query.city = this.selectedLocationValue;
      }
    }

    this.$router.push({ name: 'PropertiesMap', query });
  },
    // Formatea el precio con puntos como separadores de miles
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    // Método para mapear códigos de moneda a símbolos
    getCurrencySymbol(currency) {
      const currencySymbols = {
        'CLP': '$ ',
        'USD': '$ ',
        'EUR': '€ ',
        // Agrega más monedas si es necesario
      };
      return currencySymbols[currency] || currency; // Retorna el símbolo o el código si no está mapeado
    },
    // Función para obtener las propiedades desde la API con filtros
    fetchFilteredProperties() {
      this.loading = true;
      this.error = null;

      const apiUrl = "https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search";

      const params = {
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };

      if (this.selectedTipoPropiedad) {
        params.property_type = this.selectedTipoPropiedad;
      }
      if (this.selectedOperacion) {
        // Asumiendo que la API espera la descripción
        params.transaction_type = this.selectedOperacion;
      }
      if (this.selectedLocation && this.selectedLocationType) {
        if (this.selectedLocationType === 'Región') {
          params.state = this.selectedLocationValue;
        } else if (this.selectedLocationType === 'Comuna') {
          params.city = this.selectedLocationValue;
        }
      }

      console.log('Parámetros enviados a la API:', params);

      axios
        .get(apiUrl, {
          headers: {
            Accept: 'application/json',
          },
          params: params,
        })
        .then((response) => {
          console.log('Respuesta de la API:', response.data);
          if (response.data && response.data.properties) {
            this.properties = response.data.properties.map((propiedad) =>
              this.processProperty(propiedad)
            );
            this.total = this.properties.length; // Limitar a 16

            // Extraer opciones de filtros desde las propiedades actuales
            this.extractFilterOptions(this.properties);
          } else {
            this.error = 'No se encontraron propiedades con los filtros aplicados';
            this.properties = [];
            this.total = 0;
            this.tiposPropiedad = [];
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 404) {
            this.error = 'Ruta de API no encontrada (404)';
          } else {
            this.error = 'Error al obtener datos';
          }
          this.properties = [];
          this.total = 0;
          this.tiposPropiedad = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Procesa cada propiedad para mapear los campos necesarios
    processProperty(propiedad) {
      // Obtener el valor bruto de property_type
      const rawPropertyType = propiedad.property_type || this.getAttributeValue(propiedad.attributes, 'PropertyType');
      // Asegurar consistencia en mayúsculas/minúsculas
      const normalizedPropertyType = rawPropertyType.trim();

      // Mapear property_type a español
      const mappedPropertyType = this.propertyTypeMapSpanish[normalizedPropertyType] || normalizedPropertyType;

      // Obtener el valor bruto de transaction_type
      const rawTransactionType = propiedad.transaction_type || this.getAttributeValue(propiedad.attributes, 'TransactionType');
      const normalizedTransactionType = rawTransactionType.trim();

      // Mapear transaction_type a español
      const mappedTransactionType = this.transactionTypeMap[normalizedTransactionType] || this.transactionTypeMap[this.getOperacionDescripcion(normalizedTransactionType)] || normalizedTransactionType;

      // (Opcional) Agregar registros de depuración
      console.log(`Propiedad ID: ${propiedad.id}`);
      console.log(`Tipo de Propiedad Original: ${rawPropertyType}`);
      console.log(`Tipo de Propiedad Mapeado: ${mappedPropertyType}`);
      console.log(`Tipo de Transacción Original: ${rawTransactionType}`);
      console.log(`Tipo de Transacción Mapeado: ${mappedTransactionType}`);

      const processed = {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency,
        // Mapeo de property_type a español
        property_type: mappedPropertyType,
        // Mapeo de transaction_type a español
        transaction_type: mappedTransactionType,
        rooms: this.getAttributeValue(propiedad.attributes, 'Rooms'),
        bathrooms: this.getAttributeValue(propiedad.attributes, 'Bathrooms'),
        floor_area: this.getAttributeValue(propiedad.attributes, 'FloorArea'),
        plot_area: this.getAttributeValue(propiedad.attributes, 'PlotArea'),
        year: this.getAttributeValue(propiedad.attributes, 'Year'),
        parking: this.getAttributeValue(propiedad.attributes, 'Parking'),
        cellar: this.getAttributeValue(propiedad.attributes, 'Cellar'),
        orientation: this.getAttributeValue(propiedad.attributes, 'Orientation'),
        is_furnished: this.getAttributeValue(propiedad.attributes, 'IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : '',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : '',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : '',
        location: propiedad.location,
        city: propiedad.city || (propiedad.location ? propiedad.location.city : ''),
        images: propiedad.pictures.map((pic) => pic.source),
      };

      return processed;
    },
    // Obtiene el valor de un atributo por su nombre
    getAttributeValue(attributes, name) {
      const attr = attributes.find((attr) => attr.name === name);
      return attr ? attr.value : '';
    },
    // Obtiene la descripción de la operación a partir del valor
    getOperacionDescripcion(valor) {
      const operacion = this.operaciones.find(
        (op) => op.valor == valor || op.descripcion.toLowerCase() === valor.toLowerCase()
      );
      return operacion ? operacion.descripcion : valor;
    },
    // Extrae las opciones únicas para cada filtro
    extractFilterOptions(propiedades) {
      const tiposSet = new Set();

      propiedades.forEach((propiedad) => {
        if (propiedad.property_type) tiposSet.add(propiedad.property_type);
      });

      this.tiposPropiedad = Array.from(tiposSet).filter(Boolean).sort();
    },
    // Aplicar filtros y obtener propiedades filtradas
    applyFilters() {
     const query = {};

      if (this.selectedTipoPropiedad) {
        query.property_type = this.selectedTipoPropiedad;
      }

      // Mapeo para transaction_type: convertir "Arriendo" a "For Rent" y "Venta" a "For Sale"
      if (this.selectedOperacion) {
        const operacionLower = this.selectedOperacion.toLowerCase();
        if (operacionLower.includes('arriendo')) {
          query.transaction_type = 'For Rent';
        } else if (operacionLower.includes('venta')) {
          query.transaction_type = 'For Sale';
        } else {
          query.transaction_type = this.selectedOperacion;
        }
      }

      if (this.selectedLocation && this.selectedLocationType) {
        if (this.selectedLocationType === 'Región') {
          query.state = this.selectedLocationValue;
        } else if (this.selectedLocationType === 'Comuna') {
          query.city = this.selectedLocationValue;
        }
      }

      // Redirigir a AllProperties con parámetros de consulta
      this.$router.push({ name: 'AllProperties', query });
            
     },
    // Resetear todos los filtros
    resetFilters() {
      this.selectedTipoPropiedad = '';
      this.selectedOperacion = '';
      this.selectedLocation = '';
      this.selectedLocationType = '';
      this.selectedLocationValue = '';
      this.applyFilters();
    },
    // Eliminado: Método changePage
    // Eliminado porque no habrá paginación

    // Eliminado: Método changeItemsPerPage
    // Eliminado porque no habrá selección de cantidad por página

    // Función para obtener las comunas desde la API
    fetchComunas() {
      const comunasApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/comunas";

      return axios
        .get(comunasApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.comunas = response.data
              .filter((comuna) => comuna.descripcion)
              .map((comuna) => ({
                key: `comuna-${comuna.valor}`,
                descripcion: comuna.descripcion,
                valor: comuna.valor,
                type: 'Comuna',
              }));
            console.log('Comunas obtenidas desde la API:', this.comunas);
          } else {
            console.error('No se encontraron comunas en la API');
            this.comunas = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener comunas:', err);
          this.comunas = [];
        });
    },
    // Función para obtener las regiones desde la API
    fetchRegiones() {
      const regionesApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/regiones";

      return axios
        .get(regionesApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.regiones = response.data
              .filter((region) => region.descripcion)
              .map((region) => ({
                key: `region-${region.valor}`,
                descripcion: region.descripcion,
                valor: region.valor,
                type: 'Región',
              }));
            console.log('Regiones obtenidas desde la API:', this.regiones);
            // Almacenar todas las regiones para las tarjetas dinámicas
            this.allRegions = this.regiones;
          } else {
            console.error('No se encontraron regiones en la API');
            this.regiones = [];
            this.allRegions = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener regiones:', err);
          this.regiones = [];
          this.allRegions = [];
        });
    },
    // Función para obtener los tipos de operación desde la API
    fetchTipoOperacion() {
      const tipoOperacionApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/tipo_operacion";

      return axios
        .get(tipoOperacionApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.operaciones = response.data.map((operacion) => ({
              valor: operacion.valor,
              descripcion: operacion.descripcion,
            }));
            console.log('Operaciones obtenidas desde la API:', this.operaciones);
          } else {
            console.error('No se encontraron tipos de operación en la API');
            this.operaciones = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener tipos de operación:', err);
          this.operaciones = [];
        });
    },
    // Función para combinar regiones y comunas
    combineLocations() {
      this.locations = [...this.regiones, ...this.comunas].sort((a, b) =>
        a.descripcion.localeCompare(b.descripcion)
      );
    },
    // Métodos para el autocompletado de ubicación
    onLocationInput() {
      if (this.selectedLocation.length > 0) {
        const searchTerm = this.selectedLocation.toLowerCase();
        this.filteredLocations = this.locations.filter((location) =>
          location.descripcion.toLowerCase().includes(searchTerm)
        );
        this.showLocationSuggestions = this.filteredLocations.length > 0;
      } else {
        this.filteredLocations = [];
        this.showLocationSuggestions = false;
        this.selectedLocationType = '';
        this.selectedLocationValue = '';
      }
    },
    selectLocation(location) {
      this.selectedLocation = location.descripcion;
      this.selectedLocationType = location.type;
      this.selectedLocationValue = this.getTrimmedRegionName(location.descripcion); // Usamos el nombre simplificado
      this.filteredLocations = [];
      this.showLocationSuggestions = false;
    },
    onLocationBlur() {
      // Retrasar el ocultamiento para permitir la selección con el mouse
      setTimeout(() => {
        this.showLocationSuggestions = false;
      }, 100);
    },
    onLocationFocus() {
      if (this.filteredLocations.length > 0) {
        this.showLocationSuggestions = true;
      }
    },
    // Método nuevo para navegar al Dashboard
    navigateToDashboard() {
      this.$router.push('/dashboard');
    },
     // Función para simplificar el nombre de la región eliminando "Región de" o "Región del"
     getTrimmedRegionName(descripcion) {
      return descripcion.replace(/^Región de\s+/i, '').replace(/^Región del\s+/i, '').replace(/^Región\s+/i, '').replace(/^Region\s+/i, '')
      .replace(/^Libertador General Bernardo\s+/i, '')
      .replace(/ del General Carlos Ibáñez del Campo$/i, '').replace(/ y la Antártica Chilena $/i, '');
    },
    // Función para obtener la ruta de la imagen de la región
    getRegionImage(descripcion) {
      // Obtener el nombre simplificado de la región
      const trimmedName = this.getTrimmedRegionName(descripcion);
      // Normalizar el nombre para coincidir con el nombre del archivo de imagen
      const imageName = this.normalizeRegionName(trimmedName);
      console.log(`Nombre simplificado: ${trimmedName}, Nombre normalizado para imagen: ${imageName}`);

      try {
        // Retornar la ruta de la imagen
        return require(`@/assets/regions/${imageName}.jpg`);
      } catch (e) {
        console.error(`Imagen para la región "${trimmedName}" no encontrada. Nombre de la imagen: ${imageName}.jpg`);
        // Retornar una imagen por defecto si no se encuentra la imagen específica
        return require(`@/assets/regions/default.jpg`);
      }
    },
    // Función para normalizar el nombre de la región (convertir a minúsculas y sin espacios)
    normalizeRegionName(name) {
      return name
        .toLowerCase()
        .normalize("NFD") // Normaliza el string para separar los acentos
        .replace(/[\u0300-\u036f]/g, "") // Elimina los acentos
        .replace(/[^a-z0-9]/g, '');
    },
    // Manejar errores de carga de imágenes
    handleImageError(event) {
      event.target.src = require('@/assets/regions/default.jpg'); // Imagen por defecto
    },
    // Función para obtener las comunas y regiones, y tipos de operación
    fetchInitialData() {
      Promise.all([this.fetchRegiones(), this.fetchComunas(), this.fetchTipoOperacion()])
        .then(() => {
          this.combineLocations();
          // Ahora que las ubicaciones y tipos de operación están cargados, obtenemos las propiedades
          this.fetchFilteredProperties();
        })
        .catch((err) => {
          console.error('Error al cargar datos iniciales:', err);
          // Aún así, intentamos cargar las propiedades
          this.fetchFilteredProperties();
        });
    },
    // Función para obtener la imagen principal de la propiedad
    getMainImage(images) {
      if (images && images.length > 0) {
        return images[0];
      }
      // Si no hay imágenes, retornar una por defecto
      return require('@/assets/regions/default-property.jpg');
    },
  },
  mounted() {
    // Obtener las regiones, comunas y tipos de operación antes de cargar las propiedades
    this.fetchInitialData();
  },
};
</script>

<style scoped>
/* Estilos existentes en HomePage.vue */

/* Estilos unificados para las tarjetas de propiedad */
.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.imagen-propiedad {
  position: relative;
}

.precio-propiedad {
  font-size: 1rem;
  color: #0d6efd;
}

.tipo-propiedad {
  color: #FF8C00; /* Naranja oscuro para resaltar */
  font-weight: bold;
  text-align: left;
}

.agente-propiedad {
  margin-bottom: 12px;
  font-size: 0.7rem;
  text-align: left;
}

.agente-propiedad span {
  color: #a9a9a9 !important;
}

/* Ajustes para el Navbar */
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  color: #555;
}

.navbar-nav .nav-link:hover {
  color: #0d6efd;
}

/* Estilos para el Carrusel */
.carousel-container {
  width: 80%;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-container .carousel-item img {
  height: 340px;
  object-fit: cover;
}

/* Ajustes para el formulario de filtros */
form {
  background-color: #e9ebed;
  padding: 20px;
  border-radius: 5px;
  border: solid 2px #cbcdcf;
}

form .form-label {
  font-weight: 500;
}

form button {
  min-width: 100px;
}

/* Estilos para la lista de propiedades */
.propiedades-listado {
  /* Bootstrap grid handles the layout */
}

/* Eliminados: Estilos para la paginación y el selector de itemsPerPage */

/* Responsividad */
@media (max-width: 576px) {
  .d-flex.justify-content-between {
    flex-direction: column;
    align-items: flex-start;
  }
  #itemsPerPage {
    width: 100%;
    margin-top: 10px;
  }

  form .row.g-3 {
    flex-direction: column;
  }

  form .col-md-3 {
    width: 100%;
  }
}

/* Ajustes adicionales para el carrusel */
.carousel-container .carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-container .carousel-control-prev-icon,
.carousel-container .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-container .carousel-control-prev-icon:hover,
.carousel-container .carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 5rem;
}

/* Estilos para el botón Buscar */
button.btn.btn-primary {
  background: #181717;
  border: solid 1px #181717;
}

/* Estilos para el autocompletado de ubicación */
.autocomplete-dropdown {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.position-relative {
  position: relative;
}

/* Estilos para las tarjetas dinámicas de regiones */
.region-card {
  /* Ajusta el ancho según necesites */
  width: 100%;
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
.region-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
.region-image {
  object-fit: contain;
  width: 100%;
}

.region-card h5.card-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.region-card p.card-text {
  font-size: 0.85rem;
  margin-bottom: 15px;
}

.region-card h5.card-title[data-v-df02a584] {
  font-size: 1rem;
  width: 100%;
  padding: 10px;
  display: inherit;
  margin-bottom: 10px;
  bottom: 0;
  background: #181717;
  color: #fff;
  text-align: center;
}

.col.cont\.reg {
  padding: 0 3px;
}

/* Eliminación de bordes y radios duplicados */
.card {
  --bs-card-border-radius: unset !important;
}

.card-img, .card-img-top {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

/* Corrección de estilos para el título de la tarjeta */
.card-title {
  /* Eliminado float: left; */
  display: block;
  color: #585858;
  text-align: left;
  font-size: 1rem;
}

.card-title a {
  /* Asegurar que el enlace tenga color adecuado */
  color: #585858;
  text-decoration: none;
}

.card-title a:hover {
  color: #0d6efd; /* Color al pasar el mouse */
}

/* Asegurar que h5.card-title tenga suficiente espacio */
.card-body h5.card-title {
  margin-bottom: 0px;
}

/* Opcional: Ajustar la posición del precio si es necesario */
.card-price {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-img, .card-img-top{
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
    border-bottom: 1px solid #e0e0e0;
}

.card-title a {
    font-size: 0.8rem;
    font-weight: 600;
    color: #343a40;
}

.comuna {
  width: 100%;
}
.info-propiedad.d-flex.flex-wrap {
    font-size: 0.9rem;
    font-weight: 200;
}
.comuna span {
    color: #a9a9a9 !important;
    font-size: 0.7em !important;
    margin-top: 5px;
    font-weight: 400;
}
.card .card-body {
    padding-bottom: 0px;
    padding-top: 10px;
}
</style>
