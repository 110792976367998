<template>
    <section id="terminos-condiciones" class="py-5 bg-light">
    <div class="container">
        <button class="btn btn-custom mb-5" @click="$router.back()">
        ← Volver
        </button>

        <h1 class="mb-4">Términos y Condiciones de PortalUbi</h1>

        <div class="mb-4">
        <h2>1. Introducción</h2>
        <p>
            PortalUbi es una plataforma en línea dedicada a la publicación y búsqueda de bienes raíces,
            así como de servicios y productos relacionados. El objetivo de PortalUbi es permitir que
            cualquier persona pueda buscar y ofrecer propiedades nuevas y usadas, junto con otros
            servicios vinculados al rubro inmobiliario. PortalUbi es un software propiedad y administrado
            por la empresa <strong>Administración y corretaje PortalUbi Limitada</strong>.
        </p>
        <p>
            PortalUbi no mantiene relación directa ni indirecta con empresas inmobiliarias, corredoras,
            bancos, partidos políticos, grupos económicos o instituciones gremiales que puedan
            condicionar el contenido o generar sesgos en la presentación de la información. El sitio cuenta
            con políticas de uso y privacidad que establecen cómo se protege y utiliza la información
            facilitada por los usuarios.
        </p>
        </div>

        <div class="mb-4">
        <h2>2. Publicaciones y Avisos en PortalUbi</h2>
        <p>
            PortalUbi actúa únicamente como un medio de publicación. No ofrece directamente las
            propiedades o servicios publicados ni se involucra en las transacciones de arriendo o venta.
            Tampoco participa en el negocio inmobiliario ni recibe comisiones por transacciones. La
            responsabilidad sobre la veracidad, calidad, disponibilidad y atributos de las propiedades y
            productos publicados recae exclusivamente en los oferentes.
        </p>
        <p>
            PortalUbi no verifica ni garantiza la exactitud de la información publicada, incluyendo
            descripciones, precios, imágenes o estado de disponibilidad de propiedades y productos. Los
            usuarios deberán verificar directamente con los oferentes los detalles de cada oferta antes de
            realizar cualquier transacción.
        </p>
        </div>

        <div class="mb-4">
        <h2>3. Servicios y Productos de Terceros</h2>
        <p>
            La plataforma puede incluir enlaces o publicaciones de terceros que ofrecen servicios y
            productos relacionados. PortalUbi facilita el contacto entre usuarios y terceros, pero no se
            responsabiliza de la calidad, veracidad o cumplimiento de dichos servicios y productos.
            Cualquier relación contractual entre un usuario y un tercero es exclusiva entre esas partes,
            eximiendo a PortalUbi de cualquier responsabilidad derivada de dicha relación.
        </p>
        </div>

        <div class="mb-4">
        <h2>4. Obligaciones de los Oferentes</h2>
        <ul>
            <li><strong>Registro y Conocimiento de Condiciones:</strong> Toda persona o empresa que desee
            publicar propiedades, productos o servicios en PortalUbi debe registrarse como usuario
            y aceptar estos términos y condiciones.</li>
            <li><strong>Veracidad y Actualización:</strong> Los oferentes son responsables de la exactitud
            y actualización de la información publicada, debiendo verificar y actualizar datos en un
            plazo máximo de 48 horas ante cualquier cambio.</li>
            <li><strong>Responsabilidad sobre Publicaciones:</strong> El oferente asume total responsabilidad
            por la información publicada y exime a PortalUbi de cualquier reclamo relacionado con la
            veracidad, calidad o legalidad de dichos contenidos.</li>
            <li><strong>Uso Correcto de Datos de Usuarios:</strong> Si el oferente recibe datos personales de otros
            usuarios a través de PortalUbi, deberá mantener la confidencialidad y usarlos
            únicamente para fines relacionados con la propiedad o servicio ofrecido, respetando la
            privacidad del usuario.</li>
        </ul>
        <p>
            PortalUbi se reserva el derecho de eliminar publicaciones que infrinjan estos términos,
            incluyendo contenido erróneo, engañoso o inapropiado.
        </p>
        </div>

        <div class="mb-4">
        <h2>5. Exención de Responsabilidad de PortalUbi</h2>
        <p>
            PortalUbi no tiene injerencia en la gestión, diseño, construcción o aspectos comerciales de las
            propiedades y productos publicados. La plataforma se limita a ser un medio para la publicación
            y no garantiza la veracidad de las imágenes, descripciones ni la disponibilidad real de los
            bienes o servicios. Tampoco se hace responsable de incidentes técnicos, interrupciones del
            servicio, virus u otros daños directos o indirectos relacionados con el uso del sitio.
        </p>
        </div>

        <div class="mb-4">
        <h2>6. Requisitos y Condiciones de las Publicaciones</h2>
        <p>
            Los oferentes deben proporcionar información básica, como ubicación (comuna) y
            precio en moneda local o extranjera.
        </p>
        <p>
            PortalUbi se reserva el derecho de rechazar o eliminar publicaciones que no cumplan
            con los requisitos mínimos de información, que sean engañosas, contrarias a la ley o a
            las buenas costumbres.
        </p>
        <p>
            Las publicaciones gratuitas tendrán un plazo de vigencia determinado (por ejemplo, 2
            meses), que puede modificarse sin previo aviso. Es responsabilidad del oferente revisar
            y renovar sus publicaciones según corresponda.
        </p>
        </div>

        <div class="mb-4">
        <h2>7. Regulación de Imágenes</h2>
        <p>
            Al publicar imágenes en PortalUbi, el oferente declara contar con los derechos necesarios para
            su uso. PortalUbi no se hace responsable de reclamaciones por derechos de autor, y se
            reserva el derecho de eliminar imágenes inapropiadas o que infrinjan derechos de terceros, sin
            necesidad de aviso previo.
        </p>
        </div>

        <div class="mb-4">
        <h2>8. Publicación de Propiedades Vendidas o Arrendadas</h2>
        <p>
            No se debe publicar ni mantener en el sitio propiedades o proyectos que ya estén vendidos,
            arrendados o no disponibles. PortalUbi se reserva el derecho de retirar de inmediato cualquier
            publicación que indique un estado incorrecto o engañoso.
        </p>
        </div>

        <div class="mb-4">
        <h2>9. Definiciones de Roles</h2>
        <ul>
            <li><strong>Visitante:</strong> Cualquier persona que accede a PortalUbi sin registrarse.</li>
            <li><strong>Usuario:</strong> Visitante que se registra para acceder a servicios adicionales.</li>
            <li><strong>Oferente:</strong> Usuario registrado que publica propiedades, productos o servicios.</li>
            <li><strong>Cliente:</strong> Oferente que contrata servicios pagos en la plataforma.</li>
        </ul>
        </div>

        <div class="mb-4">
        <h2>10. Acceso y Uso de PortalUbi</h2>
        <p>
            El acceso a PortalUbi es gratuito. Los usuarios aceptan transferir los derechos de autor de sus
            avisos (textos e imágenes) a PortalUbi para efectos de publicación. PortalUbi se reserva el
            derecho de modificar o eliminar cuentas de usuario y publicaciones, así como de bloquear
            correos electrónicos y teléfonos asociados.
        </p>
        <p>
            La recopilación de datos sobre el uso del sitio, como páginas visitadas y comportamiento, se
            utiliza para mejorar el servicio, y se realiza respetando la privacidad de los usuarios de acuerdo
            a la política de privacidad.
        </p>
        </div>

        <div class="mb-4">
        <h2>11. Derechos Reservados</h2>
        <p>
            PortalUbi y su software son propiedad de <strong>Administración y corretaje PortalUbi Limitada</strong> 
            y están protegidos por derechos de autor y marcas registradas. Queda prohibida la reproducción no
            autorizada del contenido, diseño o elementos gráficos del sitio.
        </p>
        <p>
            PortalUbi se reserva el derecho a modificar unilateralmente estos Términos y Condiciones en
            cualquier momento. Se recomienda a los usuarios revisar periódicamente este documento para
            estar informados de cualquier cambio.
        </p>
        </div>

        <div class="mb-4">
        <h2>12. Uso de Cookies y Privacidad</h2>
        <p>
            PortalUbi utiliza cookies para mejorar la experiencia de usuario, analizar tráfico (por ejemplo,
            mediante Google Analytics) y recordar preferencias de búsqueda. Los usuarios consienten el
            uso de cookies al continuar navegando en el sitio. Para mayor información, consulte nuestra
            <a href="#politica-privacidad">Política de Privacidad</a>.
        </p>
        </div>

        <div class="mb-4">
        <h2>13. Obligaciones de los Usuarios</h2>
        <ul>
            <li>Mantener la confidencialidad de su nombre de usuario y contraseña.</li>
            <li>Ser prudente con la información personal compartida en foros, chats u otras áreas
            públicas.</li>
            <li>Seguir las instrucciones y directrices proporcionadas por PortalUbi para un uso
            adecuado de la plataforma.</li>
        </ul>
        </div>

        <div class="mb-4">
        <h2>14. Contacto y Soporte</h2>
        <p>
            Para consultas, reclamaciones o más información sobre PortalUbi, los usuarios pueden escribir a:
        </p>
        <p><strong>Correo electrónico:</strong> soporte@portalubi.com</p>
        </div>
    </div>
    </section>
</template>