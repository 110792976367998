<!-- src/App.vue -->
<script setup>
import { SignedIn, SignedOut, SignInButton, UserButton } from 'vue-clerk';
</script>

<template>
  <div id="app">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <!-- Logo -->
        <router-link to="/" class="navbar-brand">
          <img 
            src="./assets/ubbi-logo.png" 
            alt="Logo Ubbi" 
            class="d-inline-block align-text-top">
        </router-link>
        <!-- Botón de Toggle para dispositivos móviles -->
        <button 
          class="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarNav" 
          aria-controls="navbarNav" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Enlaces del Navbar -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto align-items-center">
            <!-- Botón "¿Quieres publicar?" redirige directamente al dashboard -->
            <li class="nav-item">
              <router-link to="/dashboard" class="btn btn-custom ms-2">
                ¿Quieres publicar?
              </router-link>
            </li>
            <!-- Botón "Tus Propiedades" que se muestra solo si el usuario está autenticado -->
            <SignedIn>
              <li class="nav-item">
                <router-link to="/dashboard" class="nav-link">Tus Propiedades</router-link>
              </li>
            </SignedIn>
            <!-- Botones de inicio de sesión y usuario -->
            <li class="nav-item">
              <SignedOut>
                <SignInButton mode="modal">
                  <button class="btn btn-link nav-link btn-custom">Iniciar Sesión</button>
                </SignInButton>
              </SignedOut>
              <SignedIn>
                <UserButton />
              </SignedIn>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Fin del Navbar -->

    <!-- Envolver router-view con keep-alive usando v-slot -->
    <router-view v-slot="{ Component }">
      <keep-alive include="AllProperties">
        <component :is="Component" />
      </keep-alive>
    </router-view>

    <!-- Footer (Opcional) -->
    <!-- Puedes agregar aquí tu Footer si deseas que aparezca en todas las páginas -->
     <!-- Dentro del <template> de App.vue, después de <router-view> -->
<footer class="mt-5">
  <div class="container">
    <div class="row">
      <!-- Información de derechos de autor -->
      <div class="col-md-4 text-md-start text-center mb-2 mb-md-0">
        <p class="mb-0">&copy; 2025 Ubbi Propiedades. Todos los derechos reservados.</p>
      </div>
      <!-- Enlaces legales -->
      <div class="col-md-4 text-center mb-2 mb-md-0">
        <a href="/politicas-de-privacidad" class="text-decoration-none me-3 text-dark">
          Políticas de Privacidad
        </a>
        <a href="/terminos-y-condiciones#terminos-condiciones" class="text-decoration-none text-dark">
          Términos y Condiciones
        </a>
      </div>
      <!-- Enlaces a redes sociales -->
      <div class="col-md-4 text-md-end text-center">
        <a href="https://facebook.com" target="_blank" class="text-dark me-2 fs-5">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com" target="_blank" class="text-dark me-2 fs-5">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://instagram.com" target="_blank" class="text-dark fs-5">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </div>
</footer>

  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.carousel__track {
    max-height: 435px;
}
.carousel__viewport {
    max-height: 435px;
}
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
/* Importar Bootstrap CSS */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Estilos globales personalizados */
body {
  background-color: #f8f9fa !important;
  font-family: "Nunito Sans", sans-serif;
}

.btn-custom {
  background-color: #181717 !important;
  color: #fff !important;
  border: none;
  padding: 6px 12px; /* Ajusta el padding según necesites */
  border-radius: 4px; /* Opcional: añade bordes redondeados */
  cursor: pointer;
  text-decoration: none; /* Asegura que no tenga subrayado */
}

/* Estilos para enlaces en el navbar */
.navbar-nav .nav-link {
  color: #000;
}

.navbar{
  background:#fff !important;
}

.navbar-nav .nav-link:hover {
  color: #555;
}

/* Estilos específicos para el botón de inicio de sesión */
button.btn.btn-link.nav-link.btn-custom {
  padding-top: 6px;
}

button {
  border: none;
  background: none;
}

.form-label {
    margin-bottom: 0px !important;
}

/* Opcional: estilos para el footer */
footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  margin-top: 50px;
}
.fw-300 {
    font-weight: 300 !important;
    font-size: 0.9rem !important;
}

.fw-200 {
    font-weight: 200 !important;
    font-size: 0.8rem !important;
}
.fw-100 {
    font-weight: 100;
    font-size: 0.6rem;
}
label.form-label.fw-300 {
    font-weight: 300;
    font-size: 0.9rem;
}
.form-select {
  font-size: inherit !important;
}
.bg-dark {
    background-color: #181717 !important;
    color: #fff !important;
}
.gm-style-iw-chr {
  display: none !important;
}
.gm-style-iw.gm-style-iw-c {
    padding: 0;
}
.gm-style-iw-d {
    overflow: hidden !important;
}
</style>
