/* eslint-disable */
// src/api.js

export const getProperties = async (params) => {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const response = await fetch(`https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search?${queryParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener propiedades:', error);
    throw error;
  }
};

export const getRegiones = async () => {
  try {
    const response = await fetch('https://2sr8k2jkp1.execute-api.us-east-1.amazonaws.com/Prod/properties/region/', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener regiones:', error);
    throw error;
  }
};

export const getComunas = async () => {
  try {
    const response = await fetch('https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/comunas', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener las comunas:', error);
    throw error;
  }
};

export const getTipoOperacion = async () => {
  try {
    const response = await fetch('https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/tipo_operacion', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tipos de operación:', error);
    throw error;
  }
};

// Agregar una función para obtener detalles de una propiedad por ID
export const getPropertyById = async (id) => {
  try {
    const response = await fetch(`https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search?id=${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();

    // Retornar la primera propiedad del array 'properties'
    if (data.properties && data.properties.length > 0) {
      return data.properties[0];
    } else {
      throw new Error('No se encontraron propiedades con el ID proporcionado.');
    }
  } catch (error) {
    console.error('Error al obtener detalles de la propiedad:', error);
    throw error;
  }
};

export const listPropertiesByAttribute = async (name, value) => {
  try {
    const query = `
      query {
        listPropertiesByAttribute(name: "${name}", value: "${value}") {
          id
          title
          description
          price
          attributes {
            name
            value
          }
        }
      }
    `;

    const response = await fetch('https://obecran4wh.execute-api.us-east-1.amazonaws.com/Prod/graphql/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {},
      }),
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data.data.listPropertiesByAttribute;
  } catch (error) {
    console.error('Error al obtener las propiedades por atributo:', error);
    throw error;
  }
};
