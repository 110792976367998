// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

// Importar Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Importar Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Importar estilos de vue3-carousel
import 'vue3-carousel/dist/carousel.css';

// Importar Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

// Importar componentes de vue3-carousel
import { Carousel, Slide } from 'vue3-carousel';

// Importar Bootstrap Icons CSS
import 'bootstrap-icons/font/bootstrap-icons.css';

import { clerkPlugin } from 'vue-clerk'

// Importar el plugin de Vue Google Maps
import VueGoogleMaps from '@fawmi/vue-google-maps';

const PUBLISHABLE_KEY = 'pk_test_bGVuaWVudC1nZWNrby0zNi5jbGVyay5hY2NvdW50cy5kZXYk'

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

// Crear la instancia de Vue
const app = createApp(App);

console.log(import.meta.env);
console.log("errorrrr");
console.log(PUBLISHABLE_KEY);
app.use(clerkPlugin, {
    publishableKey: PUBLISHABLE_KEY
  })
  
// Registrar los componentes globalmente con nombres de varias palabras
app.component('ImageCarousel', Carousel);
app.component('CarouselSlide', Slide);

// Configurar el plugin con tu API Key
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places', // Necesario para el autocompletado de lugares
  },
});

// Integrar Vuex y el router
app.use(store);
app.use(router);

// Montar la aplicación
app.mount('#app');
