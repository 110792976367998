<!-- src/components/PropertyDetail.vue -->
<template>
  <div class="container my-5" v-if="property">
    <!-- Botón de Volver -->
    <button class="btn btn-custom mb-3" @click="$router.back()">
      ← Volver a la busqueda
    </button>
    <!-- Breadcrumbs -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">Inicio</router-link></li>
        <li class="breadcrumb-item"><router-link to="/">Propiedades</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ property.title }}</li>
      </ol>
    </nav>

    <!-- Sección Principal -->
    <div class="row">
      <!-- Galería de Imágenes -->
      <div class="col-lg-7 mb-4">
        <div class="card shadow-sm">
          <div class="card-body position-relative">
            <!-- Carrusel de Imágenes Principal -->
            <ImageCarousel
              ref="mainCarousel"
              :per-page="1"
              :loop="true"
              :autoplay="0"
              :navigationEnabled="false"
              v-model:currentSlide="currentSlide"
            >
              <CarouselSlide v-for="(image, index) in property.images" :key="index">
                <div class="main-slide">
                  <!-- Botón de Navegación Izquierdo -->
                  <button class="main-nav left" @click="goPrevMain" aria-label="Anterior">
                    <i class="fas fa-chevron-left"></i>
                  </button>

                  <!-- Imagen -->
                  <img :src="image" :alt="`Imagen ${index + 1}`" class="d-block w-100 rounded">

                  <!-- Botón de Navegación Derecho -->
                  <button class="main-nav right" @click="goNextMain" aria-label="Siguiente">
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </CarouselSlide>
            </ImageCarousel>

            <!-- Miniaturas -->
            <div class="thumbnails mt-3 d-flex justify-content-center flex-wrap">
              <!-- Mostrar hasta 5 miniaturas -->
              <img
                v-for="(image, index) in visibleThumbnails"
                :key="index"
                :src="image"
                :alt="`Miniatura ${index + 1}`"
                class="thumbnail img-thumbnail m-1"
                :class="{ active: currentSlide === index }"
                @click="openModalWithSlide(index)"
              >
              <!-- Si hay más de 5 imágenes, mostrar un botón con el número de imágenes adicionales -->
              <button
                v-if="additionalThumbnails.length > 0"
                class="thumbnail thumbnail-more btn btn-light m-1 d-flex align-items-center justify-content-center"
                @click="openModalWithSlide(5)"
                aria-label="Ver más imágenes"
              >
                +{{ additionalThumbnails.length }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Información de la Propiedad -->
      <div class="col-lg-5">
        <div class="card shadow-sm">
          <div class="card-body pb-0">
            <span>
              {{ translatePropertyType(property.property_type) }} en {{ translateTransactionType(property.transaction_type) }}
            </span>
            <h4 class="property-title">{{ property.city }}, {{ property.state }}, {{ property.country }}</h4>
            <h3 class="mb-2 price">{{ formatPrice(property.price) }}</h3>
            <div class="property-details mb-4">
              <p><strong>Superficie:</strong> {{ property.floor_area }} m²</p>
              <p><strong>Dormitorios:</strong> {{ property.rooms }}</p>
              <p><strong>Baños:</strong> {{ property.bathrooms }}</p>
              <p><strong>Estacionamientos:</strong> {{ property.parking }}</p>
            </div>
          </div>
          
          <div class="card-body  pt-0">
            <h4 property-title>Características del Inmueble</h4>
            <div class="row">
              <div class="col-md-12 pb-4">
                <div class="property-details mb-4">
                  <p><strong>Año de Construcción:</strong> {{ property.year }}</p>
                  <p><strong>Orientación:</strong> {{ property.orientation }}</p>
                  <p><strong>¿Está amueblada?</strong> {{ property.is_furnished ? 'Sí' : 'No' }}</p>
                  <!-- Agrega más características según sea necesario -->
                </div>
                
              </div>
              <button class="btn btn-custom btn-lg w-100">Contactar</button>
            <!-- Botón de WhatsApp -->
            <button class="btn btn-success btn-lg w-100 mt-3" @click="openWhatsApp">
              <i class="fab fa-whatsapp"></i> Contactar por WhatsApp
            </button>
            </div>

          </div>
        
        </div>
      </div>
    </div>

    <!-- Descripción -->
    <div class="row mt-5">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <h4>Descripción</h4>
            <p class="fw-200">{{ property.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Información del Agente y Ubicación -->
    <div class="row mt-5">
      <!-- Información del Agente -->
      <div class="col-lg-6 mb-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h4>Información del Agente</h4>
            <div class="property-details">
              <p><strong>Nombre:</strong> {{ property.agent_name }}</p>
              <p><strong>Email:</strong> <a :href="`mailto:${property.agent_email}`">{{ property.agent_email }}</a></p>
              <p><strong>Teléfono:</strong> <a :href="`tel:${property.agent_phone}`">{{ property.agent_phone }}</a></p>
            </div>
          </div>
        </div>
      </div>

      <!-- Ubicación -->
      <div class="col-lg-6 mb-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h4>Ubicación</h4>
            <p class="fw-200"> {{ property.city }}, {{ property.state }}, {{ property.country }}</p>
            <div class="map-container">
              <div id="map" ref="map" style="width: 100%; height: 300px;"></div>
              <p v-if="!property.latitude || !property.longitude">No hay información de ubicación disponible.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nuevo Modal para Imágenes Ampliadas -->
    <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <!-- Carrusel de Imágenes dentro del Modal -->
        <ImageCarousel
          :key="modalCarouselKey"
          ref="modalCarousel"
          :per-page="1"
          :loop="true"
          :navigationEnabled="false"
          v-model:currentSlide="modalCurrentSlide"
          class="carousel-modal"
        >
          <CarouselSlide v-for="(image, index) in property.images" :key="index">
            <div class="modal-slide">
              <!-- Botón de Navegación Izquierdo -->
              <button class="modal-nav left" @click="goPrevModal" aria-label="Anterior">
                <i class="fas fa-chevron-left"></i>
              </button>

              <!-- Imagen -->
              <img :src="image" :alt="`Imagen ampliada ${index + 1}`" class="modal-image">

              <!-- Botón de Navegación Derecho -->
              <button class="modal-nav right" @click="goNextModal" aria-label="Siguiente">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </CarouselSlide>
        </ImageCarousel>

        <!-- Botón de Cerrar -->
        <button class="modal-close" @click="closeModal" aria-label="Cerrar">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Estado de Carga -->
  <div v-else-if="loading" class="text-center my-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
    <p class="mt-3">Cargando detalles de la propiedad...</p>
  </div>

  <!-- Mensaje de Error -->
  <div v-else-if="error" class="text-center my-5">
    <div class="alert alert-danger" role="alert">
      {{ error }}
    </div>
  </div>
</template>

<script>
/* global google */
import { getPropertyById } from '@/services/api.js';
import { Carousel as ImageCarousel, Slide as CarouselSlide } from 'vue3-carousel';

const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

export default {
  name: 'PropertyDetail',
  components: {
    ImageCarousel,
    CarouselSlide,
  },
  data() {
    return {
      property: null,
      loading: true,
      error: null,
      currentSlide: 0,
      modalCurrentSlide: 0,
      isModalOpen: false,
      modalCarouselKey: 0,
      map: null,
      marker: null,
    };
  },
  computed: {
    isAuthenticated() {
    return !!this.$clerk.user;
    },
    visibleThumbnails() {
      return this.property && this.property.images.length > 5
        ? this.property.images.slice(0, 5)
        : this.property.images;
    },
    additionalThumbnails() {
      return this.property && this.property.images.length > 5
        ? this.property.images.slice(5)
        : [];
    },
  },
  methods: {
    translatePropertyType(type) {
      const propertyTypeMapSpanish = {
        House: 'Casa',
        Apartment: 'Departamento',
        Land: 'Parcela',
        Office: 'Oficina',
      };
      return propertyTypeMapSpanish[type] || type;
    },
    translateTransactionType(type) {
      const transactionTypeMap = {
        'For Sale': 'Venta',
        'For Rent': 'Arriendo',
      };
      return transactionTypeMap[type] || type;
    },

    async fetchProperty() {
      const propertyId = this.$route.params.id;
      try {
        const data = await getPropertyById(propertyId);
        console.log('Datos de la API:', data);
        if (data) {
          this.property = this.processProperty(data);
          console.log('Propiedad Procesada:', this.property);
        } else {
          this.error = 'No se encontraron detalles para esta propiedad.';
        }
      } catch (error) {
        console.error('Error al obtener los detalles de la propiedad:', error);
        this.error = 'Error al cargar los detalles de la propiedad.';
      } finally {
        this.loading = false;
      }
    },
    formatPrice(price) {
      if (this.property.currency && this.property.currency.toUpperCase() === 'UF') {
        return `${Number(price).toLocaleString('es-CL')} UF`;
      } else {
        return new Intl.NumberFormat('es-CL', { style: 'currency', currency: this.property.currency }).format(price);
      }
    },
    processProperty(propiedad) {
      const attributes = Array.isArray(propiedad.attributes) ? propiedad.attributes : [];

      const processed = {
        id: propiedad.id || 'N/A',
        title: propiedad.title || 'Sin título',
        description: propiedad.description || 'Sin descripción',
        price: propiedad.price || 0,
        currency: propiedad.currency || 'USD',
        property_type: propiedad.property_type || this.getAttributeValue(attributes, 'PropertyType') || 'N/A',
        transaction_type: propiedad.transaction_type || this.getAttributeValue(attributes, 'TransactionType') || 'N/A',
        rooms: this.getAttributeValue(attributes, 'Rooms') || 'N/A',
        bathrooms: this.getAttributeValue(attributes, 'Bathrooms') || 'N/A',
        floor_area: this.getAttributeValue(attributes, 'FloorArea') || 'N/A',
        plot_area: this.getAttributeValue(attributes, 'PlotArea') || 'N/A',
        year: this.getAttributeValue(attributes, 'Year') || 'N/A',
        parking: this.getAttributeValue(attributes, 'Parking') || 'N/A',
        cellar: this.getAttributeValue(attributes, 'Cellar') || 'N/A',
        orientation: this.getAttributeValue(attributes, 'Orientation') || 'N/A',
        is_furnished: this.getAttributeValue(attributes, 'IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : 'N/A',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : 'N/A',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : 'N/A',
        location: propiedad.location || {},
        latitude: propiedad.location && propiedad.location.latitude,
        longitude: propiedad.location && propiedad.location.longitude,
        city: propiedad.city || (propiedad.location ? propiedad.location.city : 'N/A'),
        state: propiedad.state || (propiedad.location ? propiedad.location.state : 'N/A'),
        country: propiedad.country || (propiedad.location ? propiedad.location.country : 'N/A'),
        images: propiedad.pictures ? propiedad.pictures.map((pic) => pic.source) : [],
      };

      if (processed.title.trim() === 'en CERRILLOS' && propiedad.location && propiedad.location.address) {
        processed.title = `${propiedad.location.address}`;
      }

      return processed;
    },
    getAttributeValue(attributes, name) {
      const attr = attributes.find((attr) => attr.name === name);
      return attr ? attr.value : '';
    },
    // Métodos para el carrusel principal
    goPrevMain() {
      if (this.$refs.mainCarousel && this.$refs.mainCarousel.prev) {
        this.$refs.mainCarousel.prev();
      }
    },
    goNextMain() {
      if (this.$refs.mainCarousel && this.$refs.mainCarousel.next) {
        this.$refs.mainCarousel.next();
      }
    },
    // Método para abrir WhatsApp
    openWhatsApp() {
      let phoneNumber = this.property.agent_phone;
      phoneNumber = phoneNumber.replace(/\D+/g, '');

      if (!phoneNumber.startsWith('56')) {
        phoneNumber = '56' + phoneNumber;
      }

      const city = this.property.city || '';
      const state = this.property.state || '';
      const country = this.property.country || '';

      const locationComponents = [city, state, country].filter(Boolean);
      const location = locationComponents.join(', ');

      const message = `Me interesa esta propiedad ubicada en ${location}`;
      const encodedMessage = encodeURIComponent(message);
      const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.open(url, '_blank');
    },
    // Métodos para el modal
    openModalWithSlide(index) {
      this.modalCurrentSlide = index;
      this.isModalOpen = true;
      this.modalCarouselKey += 1;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    // Métodos para navegar en el modal
    goPrevModal() {
      if (this.$refs.modalCarousel && this.$refs.modalCarousel.prev) {
        this.$refs.modalCarousel.prev();
      }
    },
    goNextModal() {
      if (this.$refs.modalCarousel && this.$refs.modalCarousel.next) {
        this.$refs.modalCarousel.next();
      }
    },
    // Método para cargar el script de Google Maps
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    // Método para inicializar el mapa
    initMap() {
      if (this.property && this.property.latitude && this.property.longitude) {
        const lat = parseFloat(this.property.latitude);
        const lng = parseFloat(this.property.longitude);

        const mapOptions = {
          center: { lat, lng },
          zoom: 17,
        };

        this.map = new google.maps.Map(this.$refs.map, mapOptions);

        this.marker = new google.maps.Marker({
          position: { lat, lng },
          map: this.map,
        });
      }
    },
  },
  mounted() {
    this.fetchProperty().then(() => {
      this.loadGoogleMapsScript()
        .then(() => {
          this.initMap();
        })
        .catch((error) => {
          console.error('Error al cargar Google Maps:', error);
        });
    });
  },
};
</script>

<style scoped>
.main-slide {
    position: relative;
    height: 100% !important;
    width: 100%;
}
.main-slide img{
    position: relative;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}
.container {
  max-width: 1200px;
}

.property-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.property-details p {
  font-size: 1rem;
  color: #555;
}

.btn-primary {
  background-color: #212529;
  border-color: #212529;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-primary:hover {
  background-color: #212529;
  border-color: #212529;
}

.btn-success {
  background-color: #25D366;
  border-color: #25D366;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-success:hover {
  background-color: #1EBE5D;
  border-color: #1EBE5D;
}

.property-description p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.agent-info a {
  color: #212529;
  text-decoration: none;
}

.agent-info a:hover {
  text-decoration: underline;
}

.card {
  border: none;
  border-radius: 0.5rem;
}

.card-body {
  padding: 1.5rem;
}

.card h4 {
  margin-bottom: 0.8rem;
  color: #333;
  font-size: 1rem;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
  color: #555;
}

.list-unstyled li strong {
  color: #333;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 992px) {
  .property-title {
    font-size: 1.5rem;
  }

  .property-details p {
    font-size: 0.9rem;
  }

  /* Ajustar el tamaño de las miniaturas en pantallas pequeñas */
  .thumbnail {
    width: 60px;
    height: 60px;
  }
}

/* Estilos para las miniaturas */
.thumbnails {
  margin-top: 15px;
  overflow-x: auto;
  white-space: nowrap;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s, transform 0.3s;
}

.thumbnail.active {
  border: 2px solid #212529 !important;
}

.thumbnail:hover {
  border: 2px solid #212529;
  transform: scale(1.05);
}

/* Estilos para el botón de imágenes adicionales */
.thumbnail-more {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 2px solid #212529;
  color: #212529;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.thumbnail-more:hover {
  background-color: #212529;
  color: #fff;
  transform: scale(1.05);
}

/* Estilos para el Carrusel Principal */
.main-slide {
  position: relative;
}

.main-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10;
}

.main-nav.left {
  left: 10px;
}

.main-nav.right {
  right: 10px;
}

.main-nav:hover {
  background: rgba(0, 0, 0, 0.6);
}

.main-nav i {
  pointer-events: none;
}

/* Estilos para el Modal Personalizado */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal-slide {
  position: relative;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* Botones de Navegación en el Modal */
.modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10;
}

.modal-nav.left {
  left: 10px;
}

.modal-nav.right {
  right: 10px;
}

.modal-nav:hover {
  background: rgba(0, 0, 0, 0.6);
}

.modal-nav i {
  pointer-events: none;
}

/* Botón de Cerrar */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  z-index: 20;
  cursor: pointer;
}

.modal-close:hover {
  color: #ccc;
}

.map-container {
  margin-top: 20px;
}

div#app {
  background: #f8f9fa;
}

li.breadcrumb-item.active {
    text-transform: uppercase;
}

li.breadcrumb-item {
    font-size: 0.7rem;
    font-weight: 200;
}
.price {
    font-size: 1.5rem;
    font-weight: 100;
}
.property-details p[data-v-459d6c94] {
    font-size: 0.9rem;
    color: #555;
    font-weight: 100;
}
</style>
