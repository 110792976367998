<!-- src/components/AllProperties.vue -->
<template>
  <div class="all-properties-container container my-5">
    <h2 class="mb-4 text-center">Todas las Propiedades</h2>
    <p class="mb-5 text-center">Listado completo de propiedades disponibles.</p>

    <div class="row">
      <div class="col-lg-3 mb-4">
         <!-- Mostrar etiquetas de filtros activos -->
          <div class="mb-3">
            <span 
              v-for="(filter, index) in activeFilters" 
              :key="index" 
              class="badge bg-primary me-2"
              style="cursor: pointer;"
              @click="filter.remove"
              >
              {{ filter.label }} <span class="ms-1">&times;</span>
            </span>
          </div>
      </div>
      <div class="col-lg-9">
       <!-- BUSCADOR SUPERIOR (Regiones + Comunas unificado) -->
        <form @submit.prevent="applyTopFilters" class="mb-2 fw-200">
          <div class="row g-3 fw-200">
            <!-- Input de Ubicación (puede contener comunas o regiones) -->
            <div class="col-md-4 position-relative">
              <label for="topUbicacion" class="form-label">Ubicación</label>
              <input
                type="text"
                v-model="topSelectedUbicacion"
                id="topUbicacion"
                class="form-control"
                @input="onTopUbicacionInput"
                @blur="onTopUbicacionBlur"
                @focus="onTopUbicacionFocus"
                autocomplete="off"
                placeholder="Escribe comuna o región..."
              />
              <!-- Lista de sugerencias unificadas -->
              <ul
                v-if="topShowUbicacionSuggestions"
                class="list-group position-absolute w-100 autocomplete-dropdown"
              >
              <li
                v-for="(item, idx) in topFilteredUbicaciones"
                :key="idx"
                class="list-group-item list-group-item-action"
                @mousedown.prevent="selectTopUbicacion(item)"
              >
                {{ shortenRegion(item.descripcion) }}
                <span class="badge float-end" style="font-size: 0.66rem;background-color:#181717;">
                  {{ item.type }}
                </span>
              </li>

              </ul>
            </div>

            <!-- Filtro Operación (opcional) -->
            <div class="col-md-3">
              <label for="topOperacion" class="form-label">Operación</label>
              <select v-model="topSelectedOperacion" id="topOperacion" class="form-select">
                <option value="">Seleccionar operación</option>
                <option
                  v-for="operacion in operaciones"
                  :key="operacion.valor"
                  :value="operacion.descripcion"
                >
                  {{ operacion.descripcion }}
                </option>
              </select>
            </div>

            <!-- SELECT: Tipo de Propiedad (House, Apartment, Land) -->
            <div class="col-md-2">
              <label for="topPropertyType" class="form-label">Tipo de Propiedad</label>
              <select v-model="topSelectedPropertyType" id="topPropertyType" class="form-select">
                <option value="">Cualquiera</option>
                <!-- El value debe ser House, Apartment o Land para el backend -->
                <option value="House">Casa</option>
                <option value="Apartment">Departamento</option>
                <option value="Office">Oficina</option>
                <option value="Land">Terreno</option>
              </select>
            </div>

            <div class="col-md-3 mt-3">
              <button type="submit" class="btn bg-dark">Buscar</button>
              <button type="button" class="btn btn-secondary ms-2" @click="resetTopFilters">
                Limpiar Filtros
              </button>
            </div>
          </div>
        </form>
        <!-- FIN BUSCADOR SUPERIOR -->

         <!-- Mostrar contador de propiedades -->
         <p class="text-end mb-2 fw-200">
          Resultado{{ total === 1 ? '' : 's' }}: {{ total }} 
          {{ total === 1 ? 'propiedad' : 'propiedades' }}
        </p>
      </div>
    </div>
    <div class="row">
      <!-- SIDEBAR Izquierdo: Filtros + Regiones/Comunas -->
      <div class="col-lg-3 mb-4">
        <!-- Filtros de Búsqueda (sidebar) -->
        <div class="card shadow-sm">
          <div class="card-body">
            
            <!-- Número de Dormitorios -->
            <div class="mb-2">
              <label for="rooms" class="form-label fw-title">Número de Dormitorios</label>
              <select
                id="rooms"
                class="form-select"
                v-model="filters.rooms"
                @change="applyFilters"
              >
                <option value="">Cualquiera</option>
                <option v-for="room in roomOptions" :key="room" :value="room">
                  {{ room }} Dormitorio(s)
                </option>
              </select>
            </div>

            <!-- Número de Baños -->
            <div class="mb-2">
              <label for="bathrooms" class="form-label fw-title">Número de Baños</label>
              <select
                id="bathrooms"
                class="form-select"
                v-model="filters.bathrooms"
                @change="applyFilters"
              >
                <option value="">Cualquiera</option>
                <option v-for="bathroom in bathroomOptions" :key="bathroom" :value="bathroom">
                  {{ bathroom }} Baño(s)
                </option>
              </select>
            </div>

            <!-- Número de Estacionamientos -->
            <div class="mb-2">
              <label for="parking" class="form-label fw-title">Número de Estacionamientos</label>
              <select
                id="parking"
                class="form-select"
                v-model="filters.parking"
                @change="applyFilters"
              >
                <option value="">Cualquiera</option>
                <option v-for="park in parkingOptions" :key="park" :value="park">
                  {{ park }} Estacionamiento(s)
                </option>
              </select>
            </div>

            <!-- Rango de Precios -->
            <div class="mb-2">
              <label class="form-label fw-title">Rango de Precios</label>
              <div class="d-flex gap-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Desde"
                  v-model="priceFromDisplay"
                  @input="onPriceFromInput"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Hasta"
                  v-model="priceToDisplay"
                  @input="onPriceToInput"
                />
              </div>
            </div>

            <!-- Filtro por Moneda -->
            <div class="mb-2">
              <label for="currency" class="form-label fw-title">Moneda</label>
              <select
                id="currency"
                class="form-select"
                v-model="filters.currency"
                @change="applyFilters"
              >
                <option value="">Cualquiera</option>
                <option value="CLP">CLP</option>
                <option value="UF">UF</option>
              </select>
            </div>

            <!-- Botón para Limpiar Filtros (sidebar) -->
            <div class="d-grid">
              <button class="btn btn-secondary" @click="clearFilters">
                Limpiar Filtros
              </button>
            </div>
          </div>
        </div>

        <!-- Lista de Regiones o Comunas -->
        <div class="card shadow-sm mb-4 mt-4">
          <div class="card-header">
            <h5 class="mb-0 fw-title">
              <strong v-if="!selectedRegion">Regiones</strong>
              <span v-else>Comunas de {{ selectedRegionDescripcion }}</span>
            </h5>
            <!-- Botón para volver a Regiones cuando una región está seleccionada -->
            <button
              v-if="selectedRegion"
              class="btn btn-sm btn-outline-light float-end"
              @click="resetRegion"
            >
              Volver a Regiones
            </button>
          </div>
          <div class="card-body p-0">
            <ul class="list-group list-group-flush">
              <!-- Mostrar Regiones si no hay una región seleccionada -->
              <template v-if="!selectedRegion">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center region-item fw-200"
                  :class="{ active: selectedRegion === region.valor }"
                  v-for="region in regions"
                  :key="region.valor"
                  @click="selectRegion(region.valor)"
                  style="cursor: pointer;"
                >
                  <span>{{ region.descripcion }}</span>
                  <span class="badge bg-secondary rounded-pill">{{ region.count }}</span>
                </li>
              </template>

              <!-- Mostrar Comunas si hay una región seleccionada -->
              <template v-else>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center comuna-item"
                  :class="{ active: selectedComuna === comuna.comuna }"
                  v-for="comuna in comunas"
                  :key="comuna.comuna"
                  @click="selectComuna(comuna.comuna)"
                  style="cursor: pointer;"
                >
                  <span>{{ comuna.comuna }}</span>
                  <span class="badge bg-secondary rounded-pill">{{ comuna.count }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- FIN SIDEBAR -->

      <!-- Contenido Principal: Lista de Propiedades -->
      <div class="col-lg-9">
        <!-- Estado de Carga -->
        <div v-if="loading" class="text-center my-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>

        <!-- Mensaje de Error -->
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>

        <!-- Lista de Propiedades -->
        <div
          v-if="!loading && !error && properties.length > 0"
          class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4"
        >
          <div class="col" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class="card tarjeta-propiedad h-100 shadow-sm">
              <router-link
                :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }"
                class="text-decoration-none"
              >
                <div class="imagen-propiedad position-relative">
                  <img
                    :src="getMainImage(propiedad.images)"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title"
                    @error="handleImageError($event)"
                    loading="lazy"
                  />
                </div>
              </router-link>
              <div class="card-body">
                <!-- Título de la Propiedad con Tipo de Transacción y Tipo de Propiedad en Español -->
                <h5 class="card-title">
                  <router-link
                    :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }"
                    class="text-decoration-none text-dark"
                  >
                    <span v-if="['Casa', 'Departamento', 'Parcela', 'Oficina'].includes(propiedad.property_type)">
                      {{ propiedad.property_type }} en {{ propiedad.transaction_type }}
                    </span>
                  </router-link>
                </h5>
                <h5 class="card-price">
                  <span class="small">
                    {{ getCurrencySymbol(propiedad.currency) }} {{ formatPrice(propiedad.price) }}
                  </span>
                </h5>

                <!-- Información de la Propiedad con Detalles en Fila -->
                <div class="info-propiedad d-flex flex-wrap">
                  <!-- Dormitorios -->
                  <div
                    v-if="propiedad.rooms && propiedad.rooms !== 'Sin Datos'"
                    class="d-flex align-items-center"
                  >
                    <i class="bi bi-house-door-fill me-1" aria-hidden="true"></i>
                    <span class="small">{{ propiedad.rooms }} Dormitorio(s)</span>
                  </div>

                  <!-- Baños -->
                  <div
                    v-if="propiedad.bathrooms && propiedad.bathrooms !== 'Sin Datos'"
                    class="d-flex align-items-center"
                  >
                    <span class="small">&nbsp;<strong>|</strong> {{ propiedad.bathrooms }} Baño(s)</span>
                  </div>

                  <!-- Estacionamientos -->
                  <div
                    v-if="['Casa', 'Departamento', 'Oficina'].includes(propiedad.property_type)"
                    class="d-flex align-items-center"
                  >
                    <i class="bi bi-car-front-fill me-1" aria-hidden="true"></i>
                    <span class="small">
                      <template v-if="propiedad.parking > 0">
                        {{ propiedad.parking }} Estacionamiento(s)
                      </template>
                      <template v-else>
                        Sin estacionamiento
                      </template>
                    </span>
                  </div>

                  <!-- Área Total -->
                  <div
                    v-if="propiedad.floor_area && propiedad.floor_area !== 'Sin Datos'"
                    class="d-flex align-items-center"
                  >
                    <span class="small">&nbsp;<strong>|</strong> {{ propiedad.floor_area }} m²</span>
                  </div>

                  <!-- Área de Parcela (solo para parcelas) -->
                  <div
                    v-if="
                      propiedad.property_type === 'Parcela' &&
                      propiedad.plot_area &&
                      propiedad.plot_area !== 'Sin Datos'
                    "
                    class="d-flex align-items-center"
                  >
                    <i class="bi bi-geo-alt-fill me-1" aria-hidden="true"></i>
                    <span class="small">Área de Parcela: {{ propiedad.plot_area }} m²</span>
                  </div>

                  <!-- Comuna -->
                  <div
                    v-if="propiedad.city && propiedad.city !== 'Sin Datos'"
                    class="d-flex align-items-center comuna"
                  >
                    <span class="small"><strong>{{ propiedad.city }}</strong></span>
                  </div>
                </div>

                <div class="agente-propiedad mt-2">
                  <span class="text-muted fw-200">Corredor: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mensaje Informativo si No Hay Propiedades -->
        <div v-if="!loading && !error && properties.length === 0" class="alert alert-warning">
          No se encontraron propiedades para los filtros seleccionados.
        </div>

        <!-- Paginación -->
        <nav aria-label="Page navigation" v-if="totalPages > 1">
          <ul class="pagination justify-content-center">
            <!-- Botón de Página Anterior -->
            <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a
                class="page-link"
                href="#"
                aria-label="Anterior"
                @click.prevent="changePage(currentPage - 1)"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <!-- Páginas -->
            <li
              v-for="page in paginationRange"
              :key="page"
              :class="[
                'page-item',
                { active: currentPage === page },
                { disabled: page === '...' },
              ]"
            >
              <a
                v-if="page !== '...'"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)"
              >
                {{ page }}
              </a>
              <span v-else class="page-link disabled">...</span>
            </li>

            <!-- Botón de Página Siguiente -->
            <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a
                class="page-link"
                href="#"
                aria-label="Siguiente"
                @click.prevent="changePage(currentPage + 1)"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- FIN Contenido Principal -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash'; 
import { getProperties, getRegiones } from '@/services/api.js'; // Ajusta la ruta según tu proyecto

export default {
  name: 'AllProperties',
  data() {
    return {
      // ================== PROPIEDADES ================
      properties: [],
      regions: [],
      comunas: [],               // Comunas específicas de una región
      allUbicaciones: [],        // Lista unificada (Regiones + Comunas)
      selectedRegion: null,
      selectedRegionDescripcion: '',
      selectedComuna: null,

      // Estados
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 18,
      total: 0,

      // Filtros (sidebar)
      filters: {
        transactionType: '', // "For Sale"/"For Rent"
        propertyType: '',    // "House"/"Apartment"/"Land"
        rooms: '',
        bathrooms: '',
        parking: '',
        priceFrom: null,
        priceTo: null,
        currency: '',        // Nuevo filtro para currency
      },
      roomOptions: [1, 2, 3, 4, 5],
      bathroomOptions: [1, 2, 3, 4],
      parkingOptions: [0, 1, 2, 3, 4],

      // Mapeo de transacción
      transactionTypeMap: {
        'For Sale': 'Venta',
        'For Rent': 'Arriendo',
      },
      // Mapeo de propiedad
      propertyTypeMapSpanish: {
        House: 'Casa',
        Apartment: 'Departamento',
        Land: 'Parcela',
        Office: 'Oficina',
      },

      // ===== BUSCADOR SUPERIOR (Ubicación unificada) =====
      topSelectedUbicacion: '',            // Texto que el usuario escribe (region o comuna)
      topFilteredUbicaciones: [],          // Sugerencias
      topShowUbicacionSuggestions: false,  // Mostrar dropdown
      topSelectedOperacion: '',            // “Venta” / “Arriendo”
      topSelectedPropertyType: '',         // Tipo de propiedad en buscador superior

      // Operaciones disponibles
      operaciones: [],

      debouncedApplyFilters: null, // Propiedad para la función debounce

      // Nuevas propiedades para manejo de display en inputs de precio
      priceFromDisplay: '',
      priceToDisplay: '',
    };
  },
  computed: {
    // Cálculo de totalPages, range, paginatedProperties, etc.
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    paginationRange() {
      const range = [];
      const delta = 2;
      const start = Math.max(2, this.currentPage - delta);
      const end = Math.min(this.totalPages - 1, this.currentPage + delta);

      range.push(1);
      if (start > 2) {
        range.push('...');
      }
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      if (end < this.totalPages - 1) {
        range.push('...');
      }
      if (this.totalPages > 1) {
        range.push(this.totalPages);
      }
      return range;
    },
    paginatedProperties() {
      return this.properties;
    },

    activeFilters() {
      const filters = [];

      // Filtrar por región
      if (this.selectedRegionDescripcion) {
      let shortRegion = this.selectedRegionDescripcion.toUpperCase();

      // Truncar sólo para las regiones específicas de nombre muy largo
      if (shortRegion.includes('AYSÉN DEL GENERAL CARLOS IBÁÑEZ DEL CAMPO')) {
        shortRegion = 'REGIÓN DE AYSÉN';
      } else if (shortRegion.includes('MAGALLANES Y LA ANTÁRTICA CHILENA')) {
        shortRegion = 'REGIÓN DE MAGALLANES';
      }else if (shortRegion.includes('DEL LIBERTADOR GENERAL BERNARDO OHIGGINS')) {
        shortRegion = 'REGIÓN GENERAL BERNARDO OHIGGINS';
      }

      

      filters.push({
        label: `${shortRegion}`,
        remove: () => {
          this.selectedRegion = null;
          this.selectedRegionDescripcion = '';
          this.selectedComuna = null;
          this.topSelectedUbicacion = ''; // Limpiar campo de ubicación superior si aplica
          this.fetchAllProperties();
        }
      });
    }

      // Filtrar por comuna
      if (this.selectedComuna) {
        filters.push({
          label: `${this.selectedComuna}`,
          remove: () => {
            this.selectedComuna = null;
            this.topSelectedUbicacion = ''; // Limpiar campo de ubicación superior si aplica
            this.fetchAllProperties();
          }
        });
      }

      // Número de habitaciones
      if (this.filters.rooms) {
        filters.push({
          label: `${this.filters.rooms} Dormitorio(s)`,
          remove: () => {
            this.filters.rooms = '';
            this.applyFilters();
          }
        });
      }

      // Número de baños
      if (this.filters.bathrooms) {
        filters.push({
          label: `${this.filters.bathrooms} Baño(s)`,
          remove: () => {
            this.filters.bathrooms = '';
            this.applyFilters();
          }
        });
      }

      // Número de estacionamientos
      if (this.filters.parking || this.filters.parking === 0) {
        filters.push({
          label: `${this.filters.parking} Estacionamiento(s)`,
          remove: () => {
            this.filters.parking = '';
            this.applyFilters();
          }
        });
      }

      // Rango de precios - Desde
      if (this.filters.priceFrom) {
        filters.push({
          label: `Desde: ${this.getCurrencySymbol(this.filters.currency)} ${this.formatPrice(this.filters.priceFrom)}`,
          remove: () => {
            this.filters.priceFrom = null;
            this.priceFromDisplay = '';
            this.applyFilters();
          }
        });
      }

      // Rango de precios - Hasta
      if (this.filters.priceTo) {
        filters.push({
          label: `Hasta: ${this.getCurrencySymbol(this.filters.currency)} ${this.formatPrice(this.filters.priceTo)}`,
          remove: () => {
            this.filters.priceTo = null;
            this.priceToDisplay = '';
            this.applyFilters();
          }
        });
      }

      // Filtro por moneda
      if (this.filters.currency) {
        filters.push({
          label: `${this.filters.currency}`,
          remove: () => {
            this.filters.currency = '';
            this.applyFilters();
          }
        });
      }

      // Tipo de propiedad
      if (this.filters.propertyType) {
        const typeLabel = this.propertyTypeMapSpanish[this.filters.propertyType] || this.filters.propertyType;
        filters.push({
          label: `${typeLabel}`,
          remove: () => {
            this.filters.propertyType = '';
            this.topSelectedPropertyType = ''; // Limpiar campo de tipo de propiedad superior
            this.applyFilters();
          }
        });
      }

      // Tipo de operación
      if (this.filters.transactionType) {
        const opMap = { 'For Sale': 'Venta', 'For Rent': 'Arriendo' };
        const opLabel = opMap[this.filters.transactionType] || this.filters.transactionType;
        filters.push({
          label: `${opLabel}`,
          remove: () => {
            this.filters.transactionType = '';
            this.topSelectedOperacion = ''; // Limpiar campo de operación superior
            this.applyFilters();
          }
        });
      }

      return filters;
    }
  },
  methods: {
    // Método auxiliar para aplicar filtros desde la URL
    applyFiltersFromRoute() {
      const query = this.$route.query;
      // Reiniciar filtros antes de aplicar nuevos
      this.filters.propertyType = '';
      this.filters.transactionType = '';
      this.selectedRegion = null;
      this.selectedRegionDescripcion = '';
      this.selectedComuna = null;
      this.topSelectedUbicacion = '';  
      this.topSelectedOperacion = '';       // Reiniciar campo Operación superior
      this.topSelectedPropertyType = '';    // Reiniciar campo Tipo de Propiedad superior


    // Asignación de filtros y actualización de campos superiores según los parámetros
    if (query.property_type) {
      this.filters.propertyType = query.property_type;
      this.topSelectedPropertyType = query.property_type;
    }
    if (query.transaction_type) {
      this.filters.transactionType = query.transaction_type;
      // Mapear el valor a la representación en español para el campo superior
      if (query.transaction_type === 'For Rent') {
        this.topSelectedOperacion = 'Arriendo';
      } else if (query.transaction_type === 'For Sale') {
        this.topSelectedOperacion = 'Venta';
      } else {
        this.topSelectedOperacion = query.transaction_type;
      }
    }
    if (query.state) {
      this.selectedRegion = query.state;
      this.selectedRegionDescripcion = query.state;
      this.topSelectedUbicacion = query.state;
    }
    if (query.city) {
      this.selectedComuna = query.city;
      this.topSelectedUbicacion = query.city;
    }
    },
    shortenRegion(text) {
      if (!text) return '';
      const upperText = text.toUpperCase();

      if (upperText.includes('AYSÉN DEL GENERAL CARLOS IBÁÑEZ DEL CAMPO')) {
        return 'REGIÓN DE AYSÉN';
      } else if (upperText.includes('MAGALLANES Y LA ANTÁRTICA CHILENA')) {
        return 'REGIÓN DE MAGALLANES';
      }else if (upperText.includes('DEL LIBERTADOR GENERAL BERNARDO OHIGGINS')) {
        return 'REGIÓN DE BERNARDO OHIGGINS';
      }
      return text;
    },
    // =====================================================================
    // A) FUNCIONES AUXILIARES (moneda/precio)
    // =====================================================================
    getCurrencySymbol(currency) {
      const currencySymbols = {
        CLP: '$',
        USD: '$',
        EUR: '€',
      };
      return currencySymbols[currency] || currency;
    },
    formatPrice(price) {
      if (typeof price !== 'number') return '0';
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    // Función para formatear valores de entrada en precio
    formatInputPrice(value) {
      if (!value) return '';
      const numericValue = value.replace(/\./g, '').replace(/[^0-9]/g, '');
      return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    // =====================================================================
    // 1) OBTENER Y UNIFICAR TODAS LAS UBICACIONES (Regiones + Comunas)
    // =====================================================================
    async fetchAllUbicaciones() {
      try {
        // 1.1) Traer regiones
        const regionData = await getRegiones(); 

        // 1.2) Traer comunas (endpoint global)
        const comunaUrl = 'https://2sr8k2jkp1.execute-api.us-east-1.amazonaws.com/Prod/properties/comuna/';
        const comunaResp = await axios.get(comunaUrl);

        // 1.3) Convertir regiones a un formato uniforme
        const regionesTransformadas = regionData.map((r) => ({
          descripcion: r.region,
          valor: r.region,
          count: r.count,
          type: 'Región',
        }));

        // 1.4) Convertir comunas a un formato uniforme
        const comunasTransformadas = comunaResp.data.map((c) => ({
          descripcion: c.comuna,
          comuna: c.comuna,
          count: c.count,
          type: 'Comuna',
        }));

        // 1.5) Unir en un solo array
        this.allUbicaciones = [...regionesTransformadas, ...comunasTransformadas];
      } catch (error) {
        console.error('Error al obtener ubicaciones:', error);
      }
    },

    // =====================================================================
    // 2) AUTOCOMPLETADO: FILTRAR ALLUBICACIONES MIENTRAS ESCRIBE
    // =====================================================================
    onTopUbicacionInput() {
      if (this.topSelectedUbicacion.length > 0) {
        const searchTerm = this.topSelectedUbicacion.toLowerCase();
        this.topFilteredUbicaciones = this.allUbicaciones.filter((item) => {
          return item.descripcion && item.descripcion.toLowerCase().includes(searchTerm);
        });
        this.topShowUbicacionSuggestions = this.topFilteredUbicaciones.length > 0;
      } else {
        this.topFilteredUbicaciones = [];
        this.topShowUbicacionSuggestions = false;
      }
    },
    onTopUbicacionFocus() {
      if (this.topFilteredUbicaciones.length > 0) {
        this.topShowUbicacionSuggestions = true;
      }
    },
    onTopUbicacionBlur() {
      setTimeout(() => {
        this.topShowUbicacionSuggestions = false;
      }, 100);
    },
    // Al hacer click, solo rellenar el input (no filtrar aún).
    selectTopUbicacion(item) {
      this.topSelectedUbicacion = item.descripcion;
      this.topFilteredUbicaciones = [];
      this.topShowUbicacionSuggestions = false;
    },

    // =====================================================================
    // 3) FILTRAR (APLICAR) RECIÉN AL PRESIONAR "BUSCAR"
    // =====================================================================
    applyTopFilters() {
      // Buscar si la ubicación escrita coincide con alguna región o comuna
      const foundItem = this.allUbicaciones.find(
        (x) => x.descripcion.toLowerCase() === this.topSelectedUbicacion.toLowerCase()
      );

      if (foundItem) {
        if (foundItem.type === 'Región') {
          // Asignamos la región
          this.selectedRegion = foundItem.valor;
          this.selectedRegionDescripcion = foundItem.descripcion;
          this.selectedComuna = null;
        } else if (foundItem.type === 'Comuna') {
          // Asignamos la comuna
          this.selectedRegion = null;
          this.selectedRegionDescripcion = '';
          this.selectedComuna = foundItem.comuna;
        }
      } else {
        // No encontró coincidencia
        this.selectedRegion = null;
        this.selectedRegionDescripcion = '';
        this.selectedComuna = null;
      }

      // Mapeo operación: "Venta" -> "For Sale", "Arriendo" -> "For Rent"
      if (this.topSelectedOperacion === 'Venta') {
        this.filters.transactionType = 'For Sale';
      } else if (this.topSelectedOperacion === 'Arriendo') {
        this.filters.transactionType = 'For Rent';
      } else {
        this.filters.transactionType = '';
      }

      // Aplicar Tipo de Propiedad desde la variable temporal
      if (this.topSelectedPropertyType) {
        this.filters.propertyType = this.topSelectedPropertyType;
      } else {
        this.filters.propertyType = '';
      }

      // Filtrar en la página 1
      this.currentPage = 1;
      this.fetchAllProperties();
    },
    resetTopFilters() {
      // Borramos el texto en el input
      this.topSelectedUbicacion = '';
      this.topFilteredUbicaciones = [];
      this.topShowUbicacionSuggestions = false;
      this.topSelectedOperacion = '';
      this.topSelectedPropertyType = ''; // Limpiar tipo de propiedad superior

      // Limpieza global
      this.clearFilters();
    },

    // =====================================================================
    // 4) OBTENER PROPIEDADES
    // =====================================================================
    async fetchAllProperties() {
      this.loading = true;
      this.error = null;

      const params = {
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };
      // Si hay región seleccionada (sidebar o buscador)
      if (this.selectedRegion) {
        params.state = this.selectedRegion;
      }
      // Si hay comuna seleccionada
      if (this.selectedComuna) {
        params.city = this.selectedComuna;
      }
      // Si hay operación => transaction_type=For Sale/For Rent
      if (this.filters.transactionType) {
        params.transaction_type = this.filters.transactionType;
      }
      // Filtrar por tipo de propiedad
      if (this.filters.propertyType) {
        params.property_type = this.filters.propertyType;
      }
      // Filtrar por currency
      if (this.filters.currency) {
        params.currency = this.filters.currency;
      }

      // Resto de filtros
      if (this.filters.rooms) {
        params.rooms = this.filters.rooms;
      }
      if (this.filters.bathrooms) {
        params.bathrooms = this.filters.bathrooms;
      }
      if (this.filters.parking !== '') {
        params.parking = this.filters.parking;
      }
      if (this.filters.priceFrom !== null && this.filters.priceFrom !== '') {
        params.price_min = this.filters.priceFrom;
      }
      if (this.filters.priceTo !== null && this.filters.priceTo !== '') {
        params.price_max = this.filters.priceTo;
      }

      console.log('PARAMS ENVIADOS AL BACKEND:', params);

      try {
        const response = await getProperties(params);
        console.log('RESPUESTA DE LA API:', response);

        if (response && response.properties) {
          this.properties = response.properties.map((prop) => this.processProperty(prop));
          this.total = response.total;
        } else {
          this.error = 'No se encontraron propiedades para los filtros aplicados.';
          this.properties = [];
          this.total = 0;
        }
      } catch (err) {
        console.error('Error al obtener propiedades:', err);
        this.error = 'Hubo un problema al obtener las propiedades.';
        this.properties = [];
        this.total = 0;
      } finally {
        this.loading = false;
      }
    },

    // =====================================================================
    // 5) MÉTODOS PARA SIDEBAR (Regiones y Comunas)
    // =====================================================================
    async fetchRegions() {
      try {
        const data = await getRegiones();
        if (data && Array.isArray(data)) {
          this.regions = data
            .map((reg) => ({
              valor: reg.region,
              descripcion: reg.region,
              count: reg.count,
              type: 'Región',
            }))
            .sort((a, b) => b.count - a.count);
        } else {
          this.error = 'No se pudo procesar la información de regiones.';
          this.regions = [];
        }
      } catch (error) {
        console.error('Error al obtener regiones:', error);
        this.error = 'Hubo un problema al obtener las regiones.';
        this.regions = [];
      }
    },
    async fetchComunasForRegion(regionValor) {
      this.loading = true;
      this.error = null;
      try {
        const url = `https://2sr8k2jkp1.execute-api.us-east-1.amazonaws.com/Prod/properties/comuna/?region=${regionValor.toUpperCase()}`;
        const response = await axios.get(url);
        if (response.data && Array.isArray(response.data)) {
          this.comunas = response.data
            .map((com) => ({
              comuna: com.comuna,
              descripcion: com.comuna,
              count: com.count,
              type: 'Comuna',
            }))
            .sort((a, b) => b.count - a.count);
        } else {
          this.error = 'No se pudo procesar la información de comunas.';
          this.comunas = [];
        }
      } catch (error) {
        console.error('Error al obtener comunas:', error);
        this.error = 'Hubo un problema al obtener las comunas.';
        this.comunas = [];
      } finally {
        this.loading = false;
      }
    },

    // =====================================================================
    // 6) OBTENER TIPOS DE OPERACIÓN (Venta, Arriendo, etc.)
    // =====================================================================
    async fetchTipoOperacion() {
      const tipoOperacionApiUrl =
        'https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/tipo_operacion';
      try {
        const response = await axios.get(tipoOperacionApiUrl);
        if (response.data && response.data.length > 0) {
          this.operaciones = response.data.map((operacion) => ({
            valor: operacion.valor,
            descripcion: operacion.descripcion,
          }));
        } else {
          console.error('No se encontraron tipos de operación en la API');
          this.operaciones = [];
        }
      } catch (err) {
        console.error('Error al obtener tipos de operación:', err);
        this.operaciones = [];
      }
    },

    // =====================================================================
    // 7) PROCESAR PROPIEDADES
    // =====================================================================
    processProperty(propiedad) {
      const getAttributeValue = (name) => {
        const attr = propiedad.attributes.find((a) => a.name === name);
        return attr ? attr.value : 'Sin Datos';
      };
      const originalTransactionType = getAttributeValue('TransactionType') || 'Sin Tipo';
      const translatedTransactionType =
        this.transactionTypeMap[originalTransactionType] || originalTransactionType;

      const originalPropertyType = getAttributeValue('PropertyType') || 'Sin Tipo';
      const translatedPropertyType =
        this.propertyTypeMapSpanish[originalPropertyType] || originalPropertyType;

      return {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency || 'CLP',
        property_type: translatedPropertyType,
        transaction_type: translatedTransactionType,
        rooms: getAttributeValue('Rooms') || 'Sin Datos',
        bathrooms: getAttributeValue('Bathrooms') || 'Sin Datos',
        floor_area: getAttributeValue('FloorArea') || 'Sin Datos',
        plot_area: getAttributeValue('PlotArea') || 'Sin Datos',
        year: getAttributeValue('Year') || 'Sin Datos',
        parking:
          getAttributeValue('Parking') !== 'Sin Datos'
            ? Number(getAttributeValue('Parking'))
            : 'Sin Datos',
        cellar: getAttributeValue('Cellar') || 'Sin Datos',
        orientation: getAttributeValue('Orientation') || 'Sin Datos',
        is_furnished: getAttributeValue('IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : 'Sin Datos',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : 'Sin Datos',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : 'Sin Datos',
        location: propiedad.location || {},
        city: propiedad.city || (propiedad.location ? propiedad.location.city : 'Sin Datos'),
        images: propiedad.pictures ? propiedad.pictures.map((pic) => pic.source) : [],
      };
    },

    // =====================================================================
    // 8) FUNCIONES DE UI (manejo de imágenes, etc.)
    // =====================================================================
    getMainImage(images) {
      if (images && images.length > 0) {
        return images[0];
      }
      return require('@/assets/regions/default-property.jpg');
    },
    handleImageError(event) {
      event.target.src = require('@/assets/regions/default-property.jpg');
    },

    // =====================================================================
    // 9) MÉTODOS DEL SIDEBAR (seleccionar region/comuna)
    // =====================================================================
    async selectRegion(regionValor) {
      if (this.selectedRegion === regionValor) {
        // Deseleccionar
        this.selectedRegion = null;
        this.selectedRegionDescripcion = '';
        this.comunas = [];
        this.selectedComuna = null;
        this.fetchAllProperties();
      } else {
        this.selectedRegion = regionValor;
        const found = this.regions.find((r) => r.valor === regionValor);
        this.selectedRegionDescripcion = found ? found.descripcion : '';
        this.selectedComuna = null;
        await this.fetchComunasForRegion(regionValor);
        this.fetchAllProperties();
      }
    },
    selectComuna(comuna) {
      if (this.selectedComuna === comuna) {
        this.selectedComuna = null;
      } else {
        this.selectedComuna = comuna;
      }
      this.currentPage = 1;
      this.loading = true;
      this.error = null;
      this.fetchAllProperties();
    },
    resetRegion() {
      this.selectedRegion = null;
      this.selectedRegionDescripcion = '';
      this.comunas = [];
      this.selectedComuna = null;
      this.fetchAllProperties();
    },

    // =====================================================================
    // 10) FILTROS Y PAGINACIÓN
    // =====================================================================
    applyFilters() {
      this.currentPage = 1;
      this.loading = true;
      this.error = null;
      this.fetchAllProperties();
    },
    clearFilters() {
      this.filters = {
        transactionType: '',
        propertyType: '',  
        rooms: '',
        bathrooms: '',
        parking: '',
        priceFrom: null,
        priceTo: null,
        currency: '',
      };
      this.selectedRegion = null;
      this.selectedRegionDescripcion = '';
      this.comunas = [];
      this.selectedComuna = null;
      this.currentPage = 1;
      // Reiniciar displays de precios
      this.priceFromDisplay = '';
      this.priceToDisplay = '';

      // Limpiar variables temporales del buscador superior
      this.topSelectedUbicacion = '';
      this.topSelectedOperacion = '';
      this.topSelectedPropertyType = '';
      
      this.fetchAllProperties();
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.loading = true;
      this.error = null;
      this.fetchAllProperties();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    // Nuevos métodos para formatear e interpretar valores de precios en inputs
    onPriceFromInput(event) {
      const rawValue = event.target.value;
      const numericValue = rawValue.replace(/\./g, '').replace(/[^0-9]/g, '');
      this.filters.priceFrom = numericValue ? parseInt(numericValue) : null;
      this.priceFromDisplay = this.formatInputPrice(rawValue);
      // Aplicar debounce si es necesario
      this.debouncedApplyFilters();
    },
    onPriceToInput(event) {
      const rawValue = event.target.value;
      const numericValue = rawValue.replace(/\./g, '').replace(/[^0-9]/g, '');
      this.filters.priceTo = numericValue ? parseInt(numericValue) : null;
      this.priceToDisplay = this.formatInputPrice(rawValue);
      // Aplicar debounce si es necesario
      this.debouncedApplyFilters();
    },
  },
  async mounted() {
    // Inicializar la función debounced después de montar el componente
    this.debouncedApplyFilters = _.debounce(this.applyFilters, 300);
    // Descargamos TODAS las ubicaciones (regiones y comunas) para el buscador
    await this.fetchAllUbicaciones();
    // Cargamos la lista de regiones (para el sidebar)
    await this.fetchRegions();
    // Cargamos lista de operaciones
    await this.fetchTipoOperacion();
    // Finalmente, traemos propiedades
    this.fetchAllProperties();
    
    // Leer filtros de la URL desde los query params
    const query = this.$route.query;
    if (query.property_type) {
      this.filters.propertyType = query.property_type;
    }
    if (query.transaction_type) {
      this.filters.transactionType = query.transaction_type;
    }
    if (query.state) {
      this.selectedRegion = query.state;
      this.selectedRegionDescripcion = query.state; // Opcional: asigna descripción adecuada si es necesario
    }
    if (query.city) {
      this.selectedComuna = query.city;
    }

    // Inicializaciones y carga de datos
    this.debouncedApplyFilters = _.debounce(this.applyFilters, 300);
    await this.fetchAllUbicaciones();
    await this.fetchRegions();
    await this.fetchTipoOperacion();
    this.fetchAllProperties();
  },
  watch: {
    '$route.query': {
      handler() {
        // Actualiza los filtros y vuelve a obtener las propiedades
        this.applyFiltersFromRoute();
        this.fetchAllProperties();
      },
      deep: true
    }
  },
  
};
</script>

<style scoped>
.autocomplete-dropdown {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000; /* Asegurar que se muestre por encima de otros elementos */
}

.all-properties-container {
  padding: 20px;
}

.all-properties-container h2 {
  margin-bottom: 20px;
}

.all-properties-container p {
  margin-bottom: 30px;
}

.card {
  border: none;
}

.list-group-item {
  transition: background-color 0.3s, color 0.3s;
}

.list-group-item.active {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.list-group-item:hover {
  background-color: #f1f1f1;
  color: #343a40;
}

.region-item,
.comuna-item {
  padding: 10px 15px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.badge {
  font-size: 0.9rem;
}

.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.imagen-propiedad {
  position: relative;
}

.card-img,
.card-img-top {
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.card-title a span {
  font-size: 0.8rem;
  font-weight: 600;
  color: #343a40;
}

.card-title a span:hover {
  color: #343a40;
}

.card-title a {
  text-decoration: none;
}

.card-title a:hover {
  text-decoration: none;
}

.pagination {
  margin-top: 20px;
}

.pagination .page-link {
  cursor: pointer;
}

@media (max-width: 992px) {
  .col-lg-3 {
    order: 2;
  }
  .col-lg-9 {
    order: 1;
  }
}

.card-header.bg-primary.text-white {
  background: #0d6efd !important;
}

.card-header.bg-primary.text-white h5 {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.form-label.fw-bold {
  font-weight: 600;
  font-size: 0.9rem;
}

.form-select,
.form-control {
  font-size: 0.9rem;
}

.btn-outline-secondary {
  font-size: 0.9rem;
}

.comuna-item[data-v-b539b2ce] {
  padding: 6px 20px;
  font-size: 0.8rem;
}

span.badge.bg-secondary.rounded-pill {
  background: #fff !important;
  color: #9a9a9a !important;
  font-size: 0.7rem;
  border: none;
  font-weight: 200;
}

.info-propiedad .bi {
  font-size: 1rem;
  color: #555;
}

.info-propiedad .small {
  font-size: 0.8rem;
  font-weight: 200 !important;
}

.card-title {
  font-size: 0.8rem;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

h5.card-price {
  font-size: 1.2rem;
}

small,
small {
  font-size: 0.875em !important;
}

.comuna {
  width: 100%;
}
.comuna span {
  color: #5c5c5c !important;
  font-size: 0.7em !important;
  margin-top: 5px;
  font-weight: 400;
}

.card {
  --bs-card-border-radius: unset !important;
}

.card-img,
.card-img-top {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

/* Estilos para el Buscador Superior */
.autocomplete-dropdown {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.position-relative {
  position: relative;
}

.col-md-3.mt-3 {
    align-content: flex-end;
}

input, select, button {
    font-weight: 200 !important;
    font-size: 0.8rem !important;
}

.bg-dark{
 background-color:#181717 !important;
 color: #fff !important;
}

.bg-dark:hover{
 background-color:#484848 !important;
 color: #fff !important;
}

.fw-title{
  font-weight: 300;
    font-size: 0.9rem;
}

span.badge.me-2 {
    background: #525252 !important;
    cursor: pointer;
    border-radius: 0;
    font-weight: 200;
    font-size: 0.7rem;
    text-transform: uppercase;
}
</style>
