// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import PropertyDetail from './components/PropertyDetail.vue';
import LoginPage from './components/LoginPage.vue';
import RegisterPage from './components/RegisterPage.vue';
import PropertyDashboard from './components/PropertyDashboard.vue';
import CreateProperty from './components/CreateProperty.vue';
import PlansPage from './components/PlansPage.vue';
import RegionProperty from './components/RegionProperty.vue';
import AllProperties from '@/components/AllProperties.vue'; 
import TerminosCondiciones from '@/components/TerminosCondiciones.vue'; 
import PoliticasPrivacidad from '@/components/PoliticasPrivacidad.vue'; 
import PropertiesMap from './components/PropertiesMap.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/property/:id',
    name: 'PropertyDetail',
    component: PropertyDetail,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
  },
  {
    path: '/dashboard',
    name: 'PropertyDashboard',
    component: PropertyDashboard,
    // meta: { requiresAuth: true }, // Opcional
  },
  {
    path: '/create-property',
    name: 'CreateProperty',
    component: CreateProperty,
    // meta: { requiresAuth: true }, // Opcional
  },
  {
    path: '/plans',
    name: 'Plans',
    component: PlansPage,
  },
  {
    path: '/region/:name',
    name: 'RegionProperty',
    component: RegionProperty,
    props: true, // Permite que los parámetros de la ruta se pasen como props al componente
  },
  {
    path: '/all-properties',
    name: 'AllProperties',
    component: AllProperties, // Añadir la nueva ruta
  },
  {
    path: '/politicas-de-privacidad',
    name: 'PoliticasPrivacidad',
    component: PoliticasPrivacidad
  },
  {
    path: '/terminos-y-condiciones',
    name: 'TerminosCondiciones',
    component: TerminosCondiciones
  },
  {
    path: '/properties-map',
    name: 'PropertiesMap',
    component: PropertiesMap
  }
  // Rutas para editar propiedades (implementa en el futuro)
  /*
  {
    path: '/edit-property/:id',
    name: 'EditProperty',
    component: EditProperty,
    props: true,
    meta: { requiresAuth: true },
  },
  */
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
